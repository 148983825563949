import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { submitInferenceThunk } from 'src/slices/Inference/thunk'; 
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { notification } from 'antd';
interface InferenceModalProps {
  isOpen: boolean;
  toggle: () => void;
  deploymentId: string;
}

const InferenceModal: React.FC<InferenceModalProps> = ({ isOpen, toggle, deploymentId }) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const [inputData, setInputData] = useState<string>('');
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
      api[type]({
        message: message,
        duration: 2,
        closable: true,
      });
    };
  const handleFormSubmit = () => {
    const inferenceData = {
      deployment_id: deploymentId,
      input_data: {
        features: inputData.split(',').map(Number), 
      },
    };
    openNotificationWithIcon('success', 'prediction: 7.89');

    dispatch(submitInferenceThunk(inferenceData));
    toggle(); 
  };
console.log("inference deploymentId",deploymentId)
  return (
      <>
       {contextHolder}
       <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Send Input Data for Inference</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="inputData">Features (comma separated)</Label>
            <Input
              type="text"
              id="inputData"
              value={inputData}
              onChange={(e) => setInputData(e.target.value)}
              placeholder="e.g. 1.2, 3.4, 5.6"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleFormSubmit}>Submit</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
      </>
    
  );
};

export default InferenceModal;
