import { createSlice } from '@reduxjs/toolkit';
import { GetAllRoomConfigurations } from './thunk';

interface IRoomInformationState {
  roomInformation: IRoomInformation[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IRoomInformationState = {
  roomInformation: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const roomInformationSlice = createSlice({
  name: 'roomInformation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRoomConfigurations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllRoomConfigurations.fulfilled, (state, action) => {
        state.roomInformation = action.payload as IRoomInformation[];
        state.isLoading = false;
      })
      .addCase(GetAllRoomConfigurations.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      });
  },
});

export default roomInformationSlice.reducer;
