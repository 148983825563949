/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Switch, Input, Tag } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { RootState } from 'src/slices';
import { CreateNewTextEntry, GetAllTexts, UpdateTextEntry } from 'src/slices/Texts/thunk';
import { AppDispatch } from 'src/store';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { IText } from 'src/utils/types/textTypes';

interface ITextProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IText | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IText | null) => void;
}

const TextsModal = ({
  isOpen,
  openNotificationWithIcon,
  setEditingData,
  setIsEditMode,
  toggle,
  initialValues,
  isEditMode,
}: ITextProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.texts);

  // State for hashtags and keywords
  const [hashtags, setHashtags] = useState<string[]>(initialValues?.hashtags || []);
  const [keywords, setKeywords] = useState<string[]>(initialValues?.keywords || []);

  const [inputValue, setInputValue] = useState('');
  const [keywordValue, setKeywordValue] = useState('');
  const { t } = useTranslation('');
  const dispatch: AppDispatch = useDispatch();

  // Update form values when in edit mode
  React.useEffect(() => {
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);
      if (initialValues) {
        setHashtags(initialValues.hashtags);
        setKeywords(initialValues.keywords);
      }
    }
  }, [initialValues, isEditMode]);

  const handleInputConfirm = (type: 'hashtags' | 'keywords') => {
    if (type === 'hashtags') {
      if (inputValue && !hashtags.includes(inputValue)) {
        setHashtags([...hashtags, inputValue]);
        setInputValue('');
      } else if (hashtags.includes(inputValue)) {
        openNotificationWithIcon('error', 'Hashtag already exists');
      }
    } else if (type === 'keywords') {
      if (keywordValue && !keywords.includes(keywordValue)) {
        setKeywords([...keywords, keywordValue]);
        setKeywordValue('');
      } else if (keywords.includes(keywordValue)) {
        openNotificationWithIcon('error', 'Keyword already exists');
      }
    }
  };

  const handleRemoveTag = (tag: string, type: 'hashtags' | 'keywords') => {
    if (type === 'hashtags') {
      setHashtags(hashtags.filter((item) => item !== tag));
    } else {
      setKeywords(keywords.filter((item) => item !== tag));
    }
  };

  // Handle Create or Update
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IText = form.getFieldsValue();
    formData.hashtags = hashtags;
    formData.keywords = keywords;

    try {
      if (isEditMode) {
        formData.id = initialValues?.id;
        const response = await dispatch(
          UpdateTextEntry({
            data: formData,
            textId: initialValues?.id as string,
          }),
        );
        if ((response.payload as IText)['id']) {
          openNotificationWithIcon('success', t('Text updated successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllTexts());
        } else {
          openNotificationWithIcon('error', t('Error updating Text'));
        }
      } else {
        const response = await dispatch(
          CreateNewTextEntry({
            data: formData,
          }),
        );
        if ((response.payload as IText)['id']) {
          openNotificationWithIcon('success', t('Text created successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllTexts());
        } else {
          openNotificationWithIcon('error', t('Error creating Text'));
        }
      }
    } catch (error) {
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  const handleCancel = () => {
    toggle();
    form.resetFields();
    setInputValue('');
    setKeywordValue('');
    setHashtags([]);
    setKeywords([]);
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };

  return (
    <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
      <ModalHeader toggle={() => handleCancel()} tag="h5">
        {isEditMode ? t('Update') : t('Create')} {t('Text')} {t('Entry')}
      </ModalHeader>
      <ModalBody className="scrollModal">
        <Form layout="vertical" form={form}>
          <Form.Item
            required
            label={t('Content')}
            name="content"
            rules={[{ required: true, message: t('Please enter content') }]}
          >
            <Input placeholder={t('Enter content')} id="textContent" className="p-2 px-2" />
          </Form.Item>

          {/* Hashtags Input */}
          <Form.Item label={t('Hashtags')}>
            <Input
              id="textHashtags"
              className="p-2 px-2"
              value={inputValue}
              placeholder={t('Enter hashtag and press enter')}
              onChange={(e) => setInputValue(e.target.value)}
              onPressEnter={() => handleInputConfirm('hashtags')}
            />
            <div style={{ marginTop: '8px' }}>
              {hashtags.map((tag) => (
                <Tag
                  key={tag}
                  closable
                  onClose={() => handleRemoveTag(tag, 'hashtags')}
                  style={{ margin: '5px', padding: '3px 10px' }}
                >
                  {tag}
                </Tag>
              ))}
            </div>
          </Form.Item>

          {/* Keywords Input */}
          <Form.Item label={t('Keywords')}>
            <Input
              id="textKeywords"
              className="p-2 px-2"
              value={keywordValue}
              placeholder={t('Enter keyword and press enter')}
              onChange={(e) => setKeywordValue(e.target.value)}
              onPressEnter={() => handleInputConfirm('keywords')}
            />
            <div style={{ marginTop: '8px' }}>
              {keywords.map((keyword) => (
                <Tag
                  style={{ margin: '5px', padding: '3px 10px' }}
                  key={keyword} // Use the keyword value as the key
                  closable
                  onClose={() => handleRemoveTag(keyword, 'keywords')}
                >
                  {keyword}
                </Tag>
              ))}
            </div>
          </Form.Item>

          <Form.Item label={t('Enabled')} name="enabled" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>

        <Col style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            id="textsCancelButton"
            disabled={isCreating || isUpdating}
            onClick={handleCancel}
            style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
          >
            {t('Cancel')}
          </Button>
          <AsyncButton
            buttonText={isEditMode ? 'Update' : 'Create'}
            isEditMode={isEditMode}
            style={{ marginTop: 20 }}
            isLoading={isCreating || isUpdating}
            onClick={handleCreateOrUpdate}
            id="textsCreateORUpdateButton"
          />
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default TextsModal;
