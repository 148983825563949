import { Form, Input, notification, Upload } from 'antd';
import { useDispatch } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AppDispatch } from 'src/store';
import { UploadOutlined } from '@ant-design/icons';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { useState } from 'react';
import { CreateDataSet, GetAllDataSets, UpdateDataSet 
} from 'src/slices/MLPreProcessingFlow/thunk';

import { IUploadedFile,IFileUpload } from 'src/utils/types/mLPreProcessingFlowTypes';
import { NotificationType } from 'src/utils/types/NotificationTypes';


interface IFileUploadModalProps {
  isOpen: boolean;
  toggle: () => void;
  initialValues?: IUploadedFile | null;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IUploadedFile | null) => void;

  initialViewValues?: IUploadedFile | null;
  isViewMode?: boolean;
  setIsViewMode: (isViewMode: boolean) => void;
   setViewData: (data: IUploadedFile | null) => void;
 
 
}

const DataSetsModal = ({
  isOpen,
  toggle,
  openNotificationWithIcon,
  initialValues,
  isEditMode = false,
  setIsEditMode,
  setEditingData,
  initialViewValues,
  isViewMode = false,
  setIsViewMode,
   setViewData,
  
}: IFileUploadModalProps) => {
  const [form] = Form.useForm();
  const dispatch: AppDispatch = useDispatch();

  const [fileList, setFileList] = useState<any[]>([]);

  // Handle file upload
  const handleFileChange = (info: any) => {
    const file = info.file;
    if (file.type !== 'text/csv') {
      openNotificationWithIcon('error', 'Only CSV files are allowed');
      return;
    }
    setFileList([file]);
  };

  

  const handleCreateOrUpdate = async () => {
    await form.validateFields();
  
    const formData = new FormData();
    const fileList = form.getFieldValue('file')?.fileList; 
  
    if (!fileList || fileList.length === 0) {
      openNotificationWithIcon('error', 'Please upload a CSV file');
      return;
    }
  
    const uploadedFile = fileList[0];
    formData.append('file', uploadedFile.originFileObj); 
  
   
    formData.append('name', form.getFieldValue('name'));
    formData.append('description', form.getFieldValue('description'));
  
 
    try {
      let response;
  
      
      if (isEditMode) {
        const datasetId = initialValues?.id;
        if (!datasetId) {
          openNotificationWithIcon('error', 'Dataset ID is missing');
          return; 
        }
        console.log("Initial Values:", initialValues); 

      
        console.log("Dataset ID:", initialValues?.id);

   
        response = await dispatch(
          UpdateDataSet({ dataSetId: datasetId, data: formData }) 
        );
   
        if (response) {
          openNotificationWithIcon('success', 'Dataset updated successfully');
        } else {
          openNotificationWithIcon('error', 'Error updating CSV file');
        }
      } else {
      
        response = await dispatch(
          CreateDataSet({ data: formData })
        );
  
        if (response) {
          openNotificationWithIcon('success', 'Dataset created successfully');
        } else {
          openNotificationWithIcon('error', 'Error uploading CSV file');
        }
      }
  
      handleCancel();
      form.resetFields();
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', 'Something went wrong');
    }
  };
  
 
  

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setFileList([]);
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }

    if (isViewMode) {
      setIsViewMode(false);
      setViewData(null);
    }

  };

  console.log('Form data:', form.getFieldsValue());
  console.log('File list:', fileList);
  console.log('initialViewValues:', initialViewValues);

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
       
          {isEditMode ? 'Update CSV' : isViewMode? 'View': 'Upload CSV'}
        </ModalHeader>
        <ModalBody className="scrollModal">

        {isViewMode ? (
            <div>
              <p><strong>Name:</strong> {initialViewValues?.name}</p>
              <p><strong>Size:</strong> {initialViewValues?.size}</p>
              <p><strong>Uploaded At:</strong> {initialViewValues?.uploaded_at && new Date(initialViewValues.uploaded_at).toLocaleDateString()}</p>

              <p><strong>Columns:</strong> {initialViewValues?.columns}</p>
            </div>
          ) : (
          <Form layout="vertical" form={form}>
            <Form.Item
              label="Upload CSV File"
              name="file"
              rules={[{ required: true, message: 'Please upload a CSV file' }]}
            >
              <Upload
                beforeUpload={() => false} 
                fileList={fileList}
                onChange={handleFileChange}
                accept=".csv"
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Select CSV File</Button>
              </Upload>
            </Form.Item>
          </Form>
          )}
          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="manage-hotel-cancel-button"
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              Cancel
            </Button>


{!isViewMode && (
            <Button
              id="fileUploadCreateOrUpdateButton"
              onClick={handleCreateOrUpdate}
              style={{ marginTop: 20 }}
            >
              {isEditMode ? 'Update' : 'Upload'}
            </Button>
             )} 

          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DataSetsModal;
