import { createSlice } from '@reduxjs/toolkit';
import { fetchModelWeights } from './thunk'; 

interface ModelWeightsState {
  weightsId: string | null;
  filePath: string | null;
  algorithm: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: ModelWeightsState = {
  weightsId: null,
  filePath: null,
  algorithm: null,
  isLoading: false,
  error: null,
};

const modelWeightsSlice = createSlice({
  name: 'modelWeights',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModelWeights.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchModelWeights.fulfilled, (state, action) => {
        state.isLoading = false;
        state.weightsId = action.payload.weights_id;
        state.filePath = action.payload.file_path;
        state.algorithm = action.payload.algorithm;
      })
      .addCase(fetchModelWeights.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch model weights';
      });
  },
});

export default modelWeightsSlice.reducer;
