import { createSlice } from '@reduxjs/toolkit';
import { fetchDeploymentStatus } from './thunk'; 

interface DeploymentState {
  deploymentId: string | null;
  status: string | null;
  progress: number | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: DeploymentState = {
  deploymentId: null,
  status: null,
  progress: null,
  isLoading: false,
  error: null,
};

const deploymentSlice = createSlice({
  name: 'deployment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeploymentStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDeploymentStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deploymentId = action.payload.deployment_id;
        state.status = action.payload.status;
        state.progress = action.payload.progress;
      })
      .addCase(fetchDeploymentStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default deploymentSlice.reducer;
