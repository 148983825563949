/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, InputNumber, notification, Select, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RootState } from 'src/slices';
import { NotificationType } from 'src/utils/types/NotificationTypes';

import { CreatePriceListData, GetAllPriceListData, UpdatePriceListData } from 'src/slices/PriceList/thunk';
import { useEffect } from 'react';
import { IPriceList } from 'src/utils/types/priceListTypes';
import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';

import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
interface IPriceListProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IPriceList | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IPriceList | null) => void;
}

const PriceListModal = ({
  isOpen,
  toggle,
  openNotificationWithIcon,
  initialValues,
  isEditMode = false,
  setIsEditMode,
  setEditingData,
}: IPriceListProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.priceList);

  const { isLoading, roomInformation } = useSelector((state: RootState) => state.roomInformation);

  const getAllRooms = (data: IRoomInformation[]) => {
    let allRooms: {
      roomType: string;
      platformId: string;
      roomId: string;
    }[] = [];

    data.forEach((item) => {
      allRooms = allRooms.concat(item.rooms);
    });

    return allRooms;
  };

  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('');

  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      const formattedValues = {
        ...initialValues,
        startDate: initialValues?.startDate ? moment(initialValues?.startDate).format('YYYY-MM-DD') : '',
        endDate: initialValues?.endDate ? moment(initialValues?.endDate).format('YYYY-MM-DD') : '',
      };
      form.setFieldsValue(formattedValues);
    }
  }, [initialValues, isEditMode]);
  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };

  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IPriceList = form.getFieldsValue();
    console.log(formData);

    try {
      if (isEditMode) {
        // ** Update Operation
        const payload = formData;
        // payload.tenant = initialValues?.tenant;
        payload.id = initialValues?.id;
        const response = await dispatch(
          UpdatePriceListData({
            data: payload,
            pricelistId: initialValues?.id as string,
          }),
        );
        // ** Handled Response
        if ((response.payload as IPriceList)['id']) {
          openNotificationWithIcon('success', t('Price List data updated successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllPriceListData());
        } else {
          openNotificationWithIcon('error', t(`Error updating Price List data`));
        }
      } else {
        // ** Create Operation
        const payload = formData;
        const response = await dispatch(
          CreatePriceListData({
            data: payload,
          }),
        );

        // ** Handled Response
        if ((response.payload as IPriceList)['id']) {
          openNotificationWithIcon('success', t('Price List data created successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllPriceListData());
        } else {
          openNotificationWithIcon('error', t(`Error creating Price List data`));
        }
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', t(`Something went wrong`));
    }
  };

  // Custom validator to check if start date is before or equal to the end date
  const validateDates = () => ({
    validator(_: any, _value: string) {
      const startDate = form.getFieldValue('startDate');
      const endDate = form.getFieldValue('endDate');
      if (startDate && endDate && moment(startDate).isAfter(moment(endDate))) {
        return Promise.reject(new Error(t('Start date cannot be after the end date')));
      }
      return Promise.resolve();
    },
  });

  // Custom validator to check for unique room type IDs
  const validateUniqueRoomTypeIds = () => ({
    validator(_: any, _value: string) {
      const items = form.getFieldValue('items') || [];
      const roomTypeIds = items.map((item: any) => item.roomTypeId);
      const duplicates = roomTypeIds.filter((id: string, index: number) => roomTypeIds.indexOf(id) !== index);
      if (duplicates.length > 0) {
        return Promise.reject(new Error(t('Room type IDs must be unique')));
      }
      return Promise.resolve();
    },
  });

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? t('Update') : t('Create')} {t('Price List')}
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label={t('Name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('Please enter name'),
                },
              ]}
            >
              <Input id="priceListName" placeholder={t('Name')} className="p-2 px-2" />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  required={true}
                  label={t('Start Date')}
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: t('Please select start date'),
                    },
                    validateDates(),
                  ]}
                >
                  <input
                    id="priceListStartDate"
                    type="date"
                    style={{
                      padding: 10,
                      borderRadius: 5,
                      width: '100%',
                      border: '1px solid #d9d9d9',
                    }}
                    value={form.getFieldValue('startDate')}
                    onChange={(e) => form.setFieldValue('startDate', e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  required={true}
                  label={t('End Date')}
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: t('Please select end date'),
                    },
                    validateDates(),
                  ]}
                >
                  <input
                    id="priceListEndDate"
                    type="date"
                    style={{
                      padding: 10,
                      borderRadius: 5,
                      width: '100%',
                      border: '1px solid #d9d9d9',
                    }}
                    value={form.getFieldValue('endDate')}
                    onChange={(e) => form.setFieldValue('endDate', e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Items List */}

            <Form.List name="items">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row gutter={16} key={key}>
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          name={[name, 'roomTypeId']}
                          label={t('Room Type')}
                          rules={[{ required: true, message: t('Missing room type') }, validateUniqueRoomTypeIds()]}
                        >
                          <Select
                            loading={isLoading}
                            id="roomTypeID"
                            placeholder={t('Room Type')}
                            // style={{ width: '100%' }}
                            allowClear
                            style={{
                              height: '40px',
                            }}
                          >
                            {getAllRooms(roomInformation)?.map((room) => (
                              <Select.Option key={room?.roomId} value={room?.roomId}>
                                {room?.roomType}
                              </Select.Option>
                            ))}
                          </Select>
                          {/* <Input id="roomTypeID" placeholder={t('Room Type ID')} /> */}
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          name={[name, 'price']}
                          label={t('Price')}
                          rules={[{ required: true, message: t('Missing price') }]}
                        >
                          <Input className="p-2 px-2" id="price" placeholder={t('Price')} type="number" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Tooltip title={t('Remove')}>
                          <Button
                            onClick={() => remove(name)}
                            style={{
                              background: 'transparent',
                              border: 'none',
                              color: 'grey',
                              marginTop: '20px',
                              fontSize: '27px',
                            }}
                          >
                            <i className="uil uil-trash-alt me-1"></i>
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button id="addPriceListItem" onClick={() => add()}>
                      <i className="uil uil-plus me-1"></i> {t('Add Item')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="specialPeriodCancelButton"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              {t('Cancel')}
            </Button>
            <AsyncButton
              buttonText={isEditMode ? t('Update') : t('Create')}
              isEditMode={isEditMode}
              style={{ marginTop: 20 }}
              isLoading={isCreating || isUpdating}
              onClick={handleCreateOrUpdate}
              id="priceListORUpdateButton"
            />
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PriceListModal;
