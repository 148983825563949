import { createSlice } from '@reduxjs/toolkit';
import { submitInferenceThunk } from './thunk';

const inferenceSlice = createSlice({
  name: 'inference',
  initialState: {
    prediction: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitInferenceThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitInferenceThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.prediction = action.payload.prediction;
      })
      .addCase(submitInferenceThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default inferenceSlice.reducer;
