import { createAsyncThunk } from '@reduxjs/toolkit';
import { PreProcessedDataSetsService } from 'src/utils/services/service/mLPreProcessingFlowService';

import { IUploadedFile, IFileUpload } from 'src/utils/types/mLPreProcessingFlowTypes';

export const CreateDataSet = createAsyncThunk(
      'dataSets/createDataSet',
      async ({ data }: { data: IFileUpload }) => {
            try {
                  const response = await PreProcessedDataSetsService.createDataSet(data);
                  return response;
                } catch (error) {
                  throw new Error('Failed to create dataset: ' + error.message);
                }
                
      }
    );
    
export const GetAllDataSets = createAsyncThunk(
  'dataSets/getAllDataSets',
  async () => {
    try {
      const response = await PreProcessedDataSetsService.getAllDataSets();
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetSingleDataSet = createAsyncThunk(
  'dataSets/getSingleDataSet',
  async ({ dataSetId }: { dataSetId: string }) => {
    try {
      const response = await PreProcessedDataSetsService.getSingleDataSet(dataSetId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateDataSet = createAsyncThunk(
  'dataSets/updateDataSet',
  async ({ dataSetId, data }: { dataSetId: string; data: IFileUpload }) => {
    try {
      const response = await PreProcessedDataSetsService.updateDataSet(dataSetId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteDataSet = createAsyncThunk(
  'dataSets/deleteDataSet',
  async ({ dataSetId }: { dataSetId: string }) => {
    try {
      const response = await PreProcessedDataSetsService.deleteDataSet(dataSetId);
      return response;
    } catch (error) {
      return error;
    }
  },
);
