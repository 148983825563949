import { createAsyncThunk } from '@reduxjs/toolkit';
import { RoomInformationService } from 'src/utils/services/service/roomInformationService';

export const GetAllRoomConfigurations = createAsyncThunk('roomInformation/getAllRoomConfigurations', async () => {
  try {
    const response = await RoomInformationService.getAllRoomConfigurations();
    return response;
  } catch (error) {
    console.error('Error fetching all room information:', error);
    throw error;
  }
});
