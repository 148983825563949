import MetaTags from 'react-meta-tags';
import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Nav, NavItem, Row, Spinner } from 'reactstrap';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import { AvForm } from 'availity-reactstrap-validation';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import DeleteModal from 'src/components/Modals/DeleteModal';
import Select from 'react-select';
import MultipleSelect from 'src/components/elements/MultipleSelect';
import { RootState } from 'src/slices';
import {
  createGeographicLocations,
  deleteGeographicLocations,
  getAllCities,
  getAllContinents,
  getAllCountries,
  getAllPois,
  getAllRegions,
  getGeographicLocations,
  updateGeographicLocations,
} from 'src/slices/GeographicalLocations/thunk';
import { IGeographicalLocations } from 'src/utils/types/geographicalLocations';
import './index.scss';
import { continents, countries, regions, city } from './DropDownStates';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TableView from 'src/components/Common/TableView/TableView';
import { FiEdit } from 'react-icons/fi';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import { notification } from 'antd';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useTranslation } from 'react-i18next';

const GeographicalLocations = () => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [shoDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showChangeModal, setShowChangeModal] = useState<boolean>(false);
  const [continentOption, setContinentOption] = useState<any>();
  const [countryOption, setCountryOption] = useState<any>();
  const [regionOption, setRegionOption] = useState<any>();
  const [cityOption, setCityOption] = useState<any>();
  const [poisOption, setPoisOption] = useState<any>([]);
  const [showCreateErrorMessage, setShowCreateErrorMessage] = useState(false);
  const [cityData, setCityData] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();

  const { locations, loading, loadingFields, pois } = useSelector((state: RootState) => state.geographicLocations);
  console.log(locations);
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  const { selectedProperty } = useSelector((state: any) => state.porperty);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const getLocationOptions = async () => {
    if (!showChangeModal && !showCreateModal) {
      dispatch(getAllCities());
      dispatch(getAllContinents());
      dispatch(getAllCountries());
      dispatch(getAllRegions());
      dispatch(getAllPois());
    }
  };

  useEffect(() => {
    if (selectedProperty) {
      dispatch(getGeographicLocations(selectedProperty));
    }
  }, [selectedProperty]);

  const contactListColumns = [
    {
      title: t('Actions'),
      dataIndex: 'x',

      render: (_cellContent: string, data: IGeographicalLocations) => {
        return (
          <div className="tableIconBlock">
            <div className="tableIcon" onClick={() => toggleEditModal(data)}>
              <FiEdit size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="No"
                okText="Yes"
                okButtonId={`confirmAndDeleteNotificationContact`}
                triggerButtonId="deleteNotificationContact"
                onConfirm={() => {
                  // TODO handle delete
                  console.log('Remove');
                  handleDelete(data);
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: t('Continent'),
      dataIndex: 'continent',
      render: (_: string, data: IGeographicalLocations) => {
        return `${data?.continent ? data?.continent : '-'}`;
      },
    },
    {
      title: t('Country'),
      dataIndex: 'country',
      render: (_: string, data: IGeographicalLocations) => {
        return `${data?.country ? data?.country : '-'}`;
      },
    },
    {
      title: t('Region'),
      dataIndex: 'region',
      render: (_: string, data: IGeographicalLocations) => {
        return `${data?.region ? data?.region : '-'}`;
      },
    },
    {
      title: t('City'),
      dataIndex: 'city',
      render: (_: string, data: IGeographicalLocations) => {
        return `${data?.city ? data?.city : '-'}`;
      },
    },
    {
      title: 'POIs',
      dataIndex: 'poi',
      render: (_: any, data: IGeographicalLocations) => {
        return (
          <div className="d-flex gap-2">
            {data?.poi.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {item} {index < data?.poi?.length - 1 && <span>,</span>}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  const closeCreateModal = () => {
    setCountryOption(null);
    setRegionOption(null);
    setCityOption(null);
    setPoisOption(null);
    setShowCreateModal(false);
  };

  const closeEditModal = () => {
    setShowChangeModal(false);
  };

  const crateModal = async () => {
    await getLocationOptions();
    setShowCreateModal(!showCreateModal);
  };

  const sendData = {
    propertyId: selectedProperty.value,
    continent: continentOption,
    country: countryOption,
    region: regionOption,
    city: cityOption,
    pois: poisOption,
  };

  const handleSubmitCreateModal = (_: IGeographicalLocations) => {
    if (!continentOption || !countryOption || !regionOption || !cityOption || poisOption.length === 0) {
      setShowCreateErrorMessage(true);
      return;
    }

    const sendData = {
      propertyId: selectedProperty.value,
      continent: continentOption,
      country: countryOption,
      region: regionOption,
      city: cityOption,
      pois: poisOption,
    };

    dispatch(createGeographicLocations(sendData));
    setShowCreateErrorMessage(false);
    closeCreateModal();
  };

  const toggleEditModal = async (data: any) => {
    await getLocationOptions();
    const pois = data.poi.map((item: any) => {
      return { name: item, id: item };
    });

    setCityOption({ label: data.city, value: data.city });
    setContinentOption({ label: data.continent, value: data.continent });
    setCountryOption({ label: data.country, value: data.country });
    setRegionOption({ label: data.region, value: data.region });
    setPoisOption(pois);

    setShowChangeModal(!showChangeModal);
  };

  const handleSubmitEditeModal = (_: IGeographicalLocations) => {
    const editData = {
      continent: continentOption.value,
      country: countryOption.value,
      city: cityOption.value,
      region: regionOption.value,
      pois: poisOption,
      selectedProperty: selectedProperty,
    };

    dispatch(updateGeographicLocations(editData));
    closeEditModal();
  };

  const handleDelete = (_: any): void => {
    openNotificationWithIcon('info', 'Feature not available');
    // setShowDeleteModal(!shoDeleteModal);
  };

  const confirmDelete = () => {
    dispatch(deleteGeographicLocations(selectedProperty));
    setShowDeleteModal(false);
  };

  useEffect(() => {
    console.log('running . . . . . .');
    const Data = getCityByName(city, countryOption);
    // setRegionsData(Data);
    setCityData(Data);
  }, [countryOption]);

  function getCityByName(cites: any, countryName: string) {
    const cities = [];
    const country = cites[countryName];
    if (country) {
      for (const regionObj of country) {
        cities.push({ label: regionObj.name, value: regionObj.name });
      }
    }
    return cities;
  }

  return (
    <>
      <div className="page-content">
        {contextHolder}
        <MetaTags>
          <title>{t('Geographical Locations')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title={t('Settings')} breadcrumbItem={t('Geographical Locations')} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div>
                        <SearchBar
                          value={searchValue}
                          onChange={handleSearchChange}
                          placeholder={t('Search locations')}
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Link to="#" className="btn btn-light" onClick={crateModal}>
                            <i className="uil uil-plus me-1"></i> {t('Add')}
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                      marginTop: '10px',
                    }}
                  >
                    <TableView
                      loading={loading}
                      columns={contactListColumns}
                      dataSource={locations?.filter((e: IGeographicalLocations) => {
                        if (searchValue === '') {
                          return e;
                        } else if (
                          e?.continent?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
                          e?.city?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
                          e?.country?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
                          e?.propertyId?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
                          e?.region?.toLowerCase()?.includes(searchValue.toLowerCase())
                        ) {
                          return e;
                        }
                      })}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*  create modal */}
          <Modal centered isOpen={showCreateModal} toggle={crateModal}>
            <ModalHeader toggle={crateModal} tag="h4">
              {t('Create')} {t('Location')}
            </ModalHeader>
            <ModalBody className="scrollModal">
              <AvForm
                onValidSubmit={(_e: any, values: any) => {
                  handleSubmitCreateModal(values);
                }}
              >
                <Row form>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Row form>
                        <Col xs={12}>
                          <div className="mb-3">
                            <label>{t('Continent')}</label>
                            <Select
                              onChange={(value: any) => {
                                setContinentOption(value.value);
                              }}
                              // isLoading={loadingFields}
                              label={t('Continent')}
                              name="continents"
                              value={continentOption?.value}
                              placeholder={t('Select continent')}
                              options={continents?.map((item) => {
                                return { label: item.name, value: item.name };
                              })}
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <label>{t('Country')}</label>
                            <Select
                              onChange={(value: any) => {
                                setCountryOption(value.value);
                              }}
                              label={t('Country')}
                              name="country"
                              // isLoading={loadingFields}
                              value={countryOption?.value}
                              placeholder={t('Select country')}
                              options={countries?.map((item) => {
                                return { label: item.name, value: item.name };
                              })}
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <label>{t('Region')}</label>
                            <Select
                              onChange={(value: any) => {
                                setRegionOption(value.value);
                              }}
                              isLoading={loadingFields}
                              label={t('Region')}
                              name="region"
                              value={regionOption?.value}
                              placeholder={t('Select region')}
                              options={regions?.map((item) => {
                                return { label: item.name, value: item.name };
                              })}
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <label>{t('City')}</label>
                            <Select
                              onChange={(value: any) => {
                                setCityOption(value.value);
                              }}
                              label={t('City')}
                              name="cities"
                              isLoading={loadingFields}
                              value={cityOption?.value}
                              placeholder={t('Select city')}
                              options={cityData}
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <label>POIs</label>
                            <MultipleSelect
                              onChange={setPoisOption}
                              label="POIs"
                              name="poi"
                              placeholder={t('Select POIs')}
                              options={pois as any}
                              isLoading={loadingFields}
                              required={true}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      {showCreateErrorMessage && <span style={{ color: 'red' }}>{t('All fields is required')}</span>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button type="button" className="btn btn-light w-sm mx-2" onClick={closeCreateModal}>
                        {t('Close')}
                      </button>
                      <button type="submit" className="btn btn-success w-sm save-user">
                        {t('Create')}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          {/*  create modal end */}

          {/*  edit modal  */}
          <Modal centered isOpen={showChangeModal} toggle={toggleEditModal}>
            <ModalHeader toggle={toggleEditModal} tag="h4">
              {t('Update')} {t('Location')}
            </ModalHeader>
            <ModalBody className="scrollModal">
              <AvForm
                onValidSubmit={(_e: any, values: any) => {
                  handleSubmitEditeModal(values);
                }}
              >
                <Row form>
                  <Col xs={12}>
                    <div className="mb-3">
                      <div className="pb-2">
                        <Row form>
                          <Col xs={12}>
                            <div className="mb-3">
                              <label>{t('Continent')}</label>
                              <Select
                                onChange={(value: any) => {
                                  setContinentOption(value);
                                }}
                                label={t('Continent')}
                                name="continents"
                                value={continentOption}
                                placeholder={t('Select continent')}
                                isLoading={loadingFields}
                                options={continents?.map((item) => {
                                  return { label: item.name, value: item.name };
                                })}
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <label>{t('Country')}</label>
                              <Select
                                onChange={(value: any) => {
                                  setCountryOption(value);
                                }}
                                label={t('Country')}
                                name="country"
                                value={countryOption}
                                placeholder={t('Select country')}
                                isLoading={loadingFields}
                                options={countries?.map((item) => {
                                  return { label: item.name, value: item.name };
                                })}
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <label>{t('Region')}</label>
                              <Select
                                onChange={(value: any) => {
                                  setRegionOption(value);
                                }}
                                label={t('Region')}
                                name="region"
                                isLoading={loadingFields}
                                value={regionOption}
                                placeholder={t('Select region')}
                                options={regions?.map((item) => {
                                  return { label: item.name, value: item.name };
                                })}
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <label>{t('City')}</label>
                              <Select
                                onChange={(value: any) => {
                                  setCityOption(value);
                                }}
                                label={t('City')}
                                name="cities"
                                value={cityOption}
                                isLoading={loadingFields}
                                placeholder={t('Select city')}
                                options={cityData}
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <label>POIs</label>
                              <MultipleSelect
                                onChange={(value: any) => {
                                  setPoisOption(value);
                                }}
                                label="POIs"
                                name="poi"
                                isLoading={loadingFields}
                                value={poisOption?.value}
                                placeholder={t('Select POIs')}
                                options={pois}
                                defaultValues={poisOption}
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button type="button" className="btn btn-light w-sm mx-2" onClick={closeEditModal}>
                                {t('Close')}
                              </button>
                              <button type="submit" className="btn btn-success w-sm save-user">
                                {t('Create')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default withRouter(GeographicalLocations);
