/* eslint-disable react-hooks/exhaustive-deps */
import { notification, Tag } from 'antd';
import { TableProps } from 'antd/lib';
import React, { useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import TableView from 'src/components/Common/TableView/TableView';
import { RootState } from 'src/slices';
import { AppDispatch } from 'src/store';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { IText } from 'src/utils/types/textTypes';
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TextsModal from './components/TextsModal';
import { useTranslation } from 'react-i18next';
import { DeleteTextEntry, GetAllTexts } from 'src/slices/Texts/thunk';

const Texts = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [editingData, setEditingData] = React.useState<IText | null>(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [api, contextHolder] = notification.useNotification();
  const { isLoading, texts, isDeleting } = useSelector((state: RootState) => state.texts);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('');

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  useEffect(() => {
    if (!isLoading && texts.length === 0) {
      dispatch(GetAllTexts());
    }
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const columns: TableProps<IText>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 50,
      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateTexts" size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="Cancel"
                okText="Proceed"
                okButtonId={`confirmAndDeleteTexts`}
                triggerButtonId="deleteText"
                onConfirm={() => {
                  deleteText(record.id as string);
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: t('Content'),
      dataIndex: 'content',
      key: 'content',
      width: 100,
    },
    {
      title: t('Hashtags'),
      dataIndex: 'hashtags',
      key: 'hashtags',
      width: 100,
      render: (hashtags: string[]) => (
        <div>
          {hashtags.map((hashtags, i) => (
            <Tag
              style={{
                margin: '5px 5px',
              }}
              key={i}
            >
              #{hashtags}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: t('Keywords'),
      dataIndex: 'keywords',
      key: 'keywords',
      width: 100,
      render: (keywords: string[]) => (
        <div>
          {keywords.map((keyword, i) => (
            <Tag
              style={{
                margin: '5px 5px',
              }}
              key={i}
            >
              {keyword}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: t('Enabled'),
      dataIndex: 'enabled',
      key: 'enabled',
      width: 40,
      render: (enabled: boolean) => (
        <div>
          <span>{enabled ? 'Yes' : 'No'}</span>
        </div>
      ),
    },
  ];

  const deleteText = async (textId: string) => {
    if (!textId) {
      openNotificationWithIcon('error', t('Text id is required'));
      return;
    }
    try {
      const response: any = await dispatch(
        DeleteTextEntry({
          textId,
        }),
      );
      console.log(response);
      if (response?.payload?.status === 204) {
        openNotificationWithIcon('success', t('Text deleted successfully'));
        dispatch(GetAllTexts());
      } else {
        openNotificationWithIcon('error', t('Error deleting Text'));
      }
    } catch (error) {
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>{t('Texts')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title={t('Settings')} breadcrumbItem={t('Texts')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                            id="textsSearchBar"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={t('Search texts by contents , keywords , hashtags')}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                              id="createTexts"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> {t('Add')}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableView
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={texts?.filter((text) => {
                          if (searchValue === '') {
                            return text;
                          } else if (
                            text?.content?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
                            text?.keywords?.join()?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
                            text?.hashtags?.join()?.toLowerCase()?.includes(searchValue?.toLowerCase())
                          ) {
                            return text;
                          }
                        })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <TextsModal
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Texts;
