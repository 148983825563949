import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deployModelThunk } from 'src/slices/DeployModel/thunk'; 
import { RootState } from 'src/store'; 
import { IModelDeploymentRequest } from 'src/utils/types/mLPreProcessingFlowTypes';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { notification } from 'antd';
import DeploymentStatusModal from './DeploymentStatusModal';
interface DeployModelModalProps {
  isOpen: boolean;
  toggle: () => void;
  weightsId: string; 
}

const DeployModelModal: React.FC<DeployModelModalProps> = ({ isOpen, toggle, weightsId }) => {
  const dispatch = useDispatch();

  // State for form inputs
  const [workflow, setWorkflow] = useState<string>('argo');
  const [cpu, setCpu] = useState<string>('4');
  const [memory, setMemory] = useState<string>('16Gi');
  const [api, contextHolder] = notification.useNotification();

  // State to manage Deployment Status Modal
const [isDeploymentStatusModalOpen, setIsDeploymentStatusModalOpen] = useState<boolean>(false);
const [currentDeploymentId, setCurrentDeploymentId] = useState<string | null>(null);

  // Redux states from the slice
  const { deployment, isLoading, error } = useSelector((state: RootState) => state.deployModel);
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
      api[type]({
        message: message,
        duration: 2,
        closable: true,
      });
    };
  // Function to generate a random deployment ID
const generateRandomDeploymentId = (): string => {
      return `${Math.random().toString(36).substr(2, 9)}`; 
    };
    
// Handle form submission
const handleFormSubmit = async () => {
      const deploymentData: IModelDeploymentRequest = {
        weights_id: weightsId,
        workflow,
        resources: {
          cpu,
          memory,
        },
      };
     
  const generatedDeploymentId = generateRandomDeploymentId();

      openNotificationWithIcon('success', 'Model deployment started using Argo Workflow.');

  toggle();

 
  openDeploymentStatusModal(generatedDeploymentId);
    };
  
const openDeploymentStatusModal = (deploymentId: string) => {
      setCurrentDeploymentId(deploymentId);
      setIsDeploymentStatusModalOpen(true);
    };
    
  useEffect(() => {
    if (deployment) {
      console.log('Model deployed successfully:', deployment);
    }
  }, [deployment]);

  return (
      <>
       {contextHolder}
       <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Deploy Model</ModalHeader>
      <ModalBody>
        {isLoading && <Spinner />}
        {error && <p style={{ color: 'red' }}>Error: {error}</p>}

        {/* Deployment Form */}
        <Form>
          <FormGroup>
            <Label for="weightsId">Weights ID</Label>
            <Input type="text" id="weightsId" value={weightsId} disabled />
          </FormGroup>

          <FormGroup>
            <Label for="workflow">Workflow</Label>
            <Input
              type="select"
              id="workflow"
              value={workflow}
              onChange={(e) => setWorkflow(e.target.value)}
            >
              <option value="argo">Argo</option>
              <option value="kubeflow">Kubeflow</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="cpu">CPU</Label>
            <Input
              type="text"
              id="cpu"
              value={cpu}
              onChange={(e) => setCpu(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label for="memory">Memory</Label>
            <Input
              type="text"
              id="memory"
              value={memory}
              onChange={(e) => setMemory(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleFormSubmit} disabled={isLoading}>
          {isLoading ? 'Deploying...' : 'Deploy'}
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
    
<DeploymentStatusModal
  isOpen={isDeploymentStatusModalOpen}
  toggle={() => setIsDeploymentStatusModalOpen(false)}
  deploymentId={currentDeploymentId}
/>
      </>
   
  );
};

export default DeployModelModal;
