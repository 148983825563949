import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModelWeights } from 'src/slices/ModelWeights/thunk'; 
import { RootState } from 'src/store'; 
import DeployModelModal from './DeployModelModal'; 

interface WeightsModalProps {
  isOpen: boolean;
  toggle: () => void;
  id: string;
}

const WeightsModal: React.FC<WeightsModalProps> = ({ isOpen, toggle, id }) => {
  const dispatch = useDispatch();
  const { weightsId,filePath,algorithm, isLoading, error } = useSelector((state: RootState) => state.modelWeights); 
  const [isDeployModalOpen, setIsDeployModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen && id) {
      dispatch(fetchModelWeights(id)); 
    }
  }, [isOpen, id, dispatch]);

  const toggleDeployModal = () => {
    setIsDeployModalOpen(!isDeployModalOpen);
  };
console.log('idididididid',id)
  return (
    <>
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Weights Information</ModalHeader>
      <ModalBody>
        {isLoading && <p>Loading weights information...</p>}
        {error && <p>Error: {error}</p>}
       
          <div>
          <p>
                Weights ID: <Button color="link" onClick={toggleDeployModal}>{weightsId}</Button> {/* Clickable Weights ID */}
              </p>
            <p>File Path: {filePath}</p>
            <p>Algorithm: {algorithm}</p>
          </div>
   
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>

      {/* Deploy Model Modal */}
      <DeployModelModal
        isOpen={isDeployModalOpen}
        toggle={toggleDeployModal}
        weightsId={weightsId || ''} 
      />
      </>
  );
};

export default WeightsModal;
