/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { ISpecialPeriods } from 'src/utils/types/specialPeriodsTypes';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { CreateSpecialPeriods, GetAllSpecialPeriods, UpdateSpecialPeriods } from 'src/slices/SpecialPeriods/thunk';
import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { useTranslation } from 'react-i18next';
interface ISpecialPeriodsProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: ISpecialPeriods | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: ISpecialPeriods | null) => void;
}

const SpecialPeriodsModal = ({
  isOpen,
  openNotificationWithIcon,
  setEditingData,
  setIsEditMode,
  toggle,
  initialValues,
  isEditMode,
}: ISpecialPeriodsProps) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.specialPeriods);
  const { selectedProperty, realFormate } = useSelector((state: RootState) => state.porperty);
  const dispatch: AppDispatch = useDispatch();

  // ** useEffect to set initial values in form
  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };

  // ** Function to handle create or update
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: ISpecialPeriods = form.getFieldsValue();
    if (!selectedProperty.value || selectedProperty.value === '') {
      openNotificationWithIcon('error', t('Property id not found'));
      return;
    }
    const startDate = new Date(formData.start);
    const endDate = new Date(formData.end);

    if (startDate > endDate) {
      openNotificationWithIcon('error', t('Invalid date range'));
      return;
    }

    // ** Get the current selected property id
    const propertyId = realFormate.find((property) => property.mandantId === selectedProperty?.value);
    formData.propertyId = propertyId?.id;

    try {
      if (isEditMode) {
        // ** Update Operation
        const payload = formData;
        payload.tenant = initialValues?.tenant;
        payload.id = initialValues?.id;
        const response = await dispatch(
          UpdateSpecialPeriods({
            data: payload,
            eventId: initialValues?.id as string,
          }),
        );
        // ** Handled Response
        if ((response.payload as ISpecialPeriods)['id']) {
          openNotificationWithIcon('success', t('Special period updated successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllSpecialPeriods());
        } else {
          openNotificationWithIcon('error', t(`Error updating special period`));
        }
      } else {
        // ** Create Operation
        const payload = formData;
        const response = await dispatch(
          CreateSpecialPeriods({
            data: payload,
          }),
        );

        // ** Handled Response
        if ((response.payload as ISpecialPeriods)['id']) {
          openNotificationWithIcon('success', t('Special period created successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllSpecialPeriods());
        } else {
          openNotificationWithIcon('error', t(`Error creating special period`));
        }
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', t(`Something went wrong`));
    }
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? t('Update') : t('Create')} {t('Special Period')}
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label={t('Title')}
              name="title"
              rules={[
                {
                  required: true,
                  message: t('Please enter title'),
                },
              ]}
            >
              <Input id="specialPeriodTitle" placeholder={t('Enter title')} className="p-2 px-2" />
            </Form.Item>
            <Form.Item
              style={{ marginTop: '-10px' }}
              required={true}
              label={t('Kind')}
              name="kind"
              rules={[
                {
                  required: true,
                  message: t('Please select period kind'),
                },
              ]}
            >
              <Select
                id="specialPeriodEventKind"
                allowClear
                placeholder={t('Select kind')}
                style={{
                  height: '40px',
                }}
              >
                <Select.Option value={'event'}>{t('Event')}</Select.Option>;
              </Select>
            </Form.Item>
            <Row>
              <Col>
                <Form.Item
                  style={{ marginTop: '-10px' }}
                  required={true}
                  label={t('Start Date')}
                  name="start"
                  rules={[
                    {
                      required: true,
                      message: t('Please select start date'),
                    },
                  ]}
                >
                  <input
                    id="specialPeriodStartDate"
                    type="date"
                    style={{
                      padding: 10,
                      borderRadius: 5,
                      width: '100%',
                      border: '1px solid #d9d9d9',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  style={{ marginTop: '-10px' }}
                  required={true}
                  label={t('End Date')}
                  name="end"
                  rules={[
                    {
                      required: true,
                      message: t('Please select end date'),
                    },
                  ]}
                >
                  <input
                    id="specialPeriodEndDate"
                    type="date"
                    style={{
                      padding: 10,
                      borderRadius: 5,
                      width: '100%',
                      border: '1px solid #d9d9d9',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="specialPeriodCancelButton"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              {t('Cancel')}
            </Button>
            <AsyncButton
              buttonText={isEditMode ? 'Update' : 'Create'}
              isEditMode={isEditMode}
              style={{ marginTop: 20 }}
              isLoading={isCreating || isUpdating}
              onClick={handleCreateOrUpdate}
              id="specialPeriodCreateORUpdateButton"
            />
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SpecialPeriodsModal;
