/* eslint-disable react-hooks/exhaustive-deps */
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification, Tooltip } from 'antd';
import { IPriceList } from 'src/utils/types/priceListTypes';
import PriceListModal from './components/PriceListModal';
import { FiEdit } from 'react-icons/fi';

import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { DeletePriceListData, GetAllPriceListData } from 'src/slices/PriceList/thunk';
import AbbreviatedId from 'src/components/Common/AbbreviatedId';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useTranslation } from 'react-i18next';
import { GetAllRoomConfigurations } from 'src/slices/RoomInformation/thunk';

const PriceList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<IPriceList | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation('');

  const { isLoading, priceList, isDeleting } = useSelector((state: RootState) => state.priceList);
  const { roomInformation } = useSelector((state: RootState) => state.roomInformation);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && priceList.length === 0) {
      dispatch(GetAllPriceListData());
    }
    dispatch(GetAllRoomConfigurations());
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const getRoomTypeByRoomId = (roomId: number | string) => {
    const getAllRooms = (data: IRoomInformation[]) => {
      let allRooms: {
        roomType: string;
        platformId: string;
        roomId: string;
      }[] = [];

      data.forEach((item) => {
        allRooms = allRooms.concat(item.rooms);
      });

      return allRooms;
    };

    const roomType = getAllRooms(roomInformation).find((room) => room.roomId === roomId);
    return roomType?.roomType;
  };

  const columns: TableProps<IPriceList>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 150,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <Tooltip title={t('Update')}>
              <div
                className="tableIcon"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsEditMode(true);
                  setEditingData(record);
                }}
              >
                <FiEdit id="updatePriceList" size={20} />
              </div>
            </Tooltip>

            <Tooltip title={t('Delete')}>
              <div className="tableIcon">
                <DeleteButton
                  title="Are you sure you want to delete?"
                  cancelText="No"
                  okText="Yes"
                  okButtonId={`confirmAndDeletePriceList`}
                  triggerButtonId="deletePriceList"
                  onConfirm={() => {
                    deletePriceList(record.id!);
                  }}
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      title: t('Name'),
      key: 'name',
      dataIndex: 'name',
      width: 250,
    },

    {
      title: t('Start Date'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: '15%',
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: t('End Date'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: '15%',
      render: (text: string) => new Date(text).toLocaleDateString(),
    },

    {
      title: t('Items'),
      dataIndex: 'items',
      key: 'items',
      render: (items: { roomTypeId: number; price: number }[]) => {
        return (
          <div>
            {items.map((item) => (
              <div key={item.roomTypeId}>
                Room Type <strong>{getRoomTypeByRoomId(item.roomTypeId)}</strong>: ${item.price.toFixed(2)}
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const deletePriceList = async (pricelistId: string) => {
    if (!pricelistId) {
      openNotificationWithIcon('error', t('Price id not found'));
      return;
    }
    try {
      const response = await dispatch(
        DeletePriceListData({
          pricelistId,
        }),
      );
      if (response.payload === pricelistId) {
        openNotificationWithIcon('success', t('Price List deleted successfully'));
        dispatch(GetAllPriceListData());
      } else {
        openNotificationWithIcon('error', t('Error deleting Price List'));
      }
    } catch (error) {
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>{t('Price List')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title={t('Settings')} breadcrumbItem={t('Price List')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                            id="priceListSearchBar"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={t('Search price list by name')}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                              id="addPriceList"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> {t('Add')}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableView
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={priceList
                          ?.filter((data: IPriceList) => {
                            if (searchValue === '') return data;
                            else if (data.name?.toLowerCase().includes(searchValue.toLowerCase())) {
                              return data;
                            }
                          })
                          ?.reverse()}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <PriceListModal
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default PriceList;
