// src/slices/hotelSearch/hotelSearchReducer.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchHotelSearchResults } from './thunk';
import { ISearchHotelResponse } from 'src/utils/types/searchTypes';


interface ISearchState {
  hotels: ISearchHotelResponse[];
  loading: boolean;
  error: string | null;
}

const initialState: ISearchState = {
  hotels: [],
  loading: false,
  error: null,
};

const hotelSearchSlice = createSlice({
  name: 'hotelSearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelSearchResults.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHotelSearchResults.fulfilled, (state, action: PayloadAction<ISearchHotelResponse>) => {
        state.hotels = [action.payload]; // Update state with the list of hotels
        state.loading = false;
      })
      .addCase(fetchHotelSearchResults.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

export default hotelSearchSlice.reducer;
