import React, { useEffect, useState } from 'react';
import { Table, Input, Button, notification, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOffers } from 'src/slices/Offer/thunk'; // Adjust path if necessary
import { RootState } from 'src/store';
import styles from './CompetitorWebsiteAnalysis.module.scss'; // Import the SCSS module

const CompetitorWebsiteAnalysis = () => {
  const [imageUrl, setImageUrl] = useState('');
  const dispatch = useDispatch();
  
  // Access offers, loading, and error from Redux state
  const { offers = [], loading = false, error = null } = useSelector((state: RootState) => state.offer || {});
  
  useEffect(() => {
    console.log('Offers state:', offers);
  }, [offers]);
  
  useEffect(() => {
    console.log('Error state:', error);
  }, [error]);

  // Define table columns
  const columns = [
    {
      title: 'Offer Type',
      dataIndex: 'Offer type', 
      key: 'offerType',
      render: (text: string) => text || '-',
    },
    {
      title: 'Percentage',
      dataIndex: 'Percentage',
      key: 'percentage',
      render: (text: string) => text || '-',
    },
    {
      title: 'Amount',
      dataIndex: 'Amount',
      key: 'amount',
      render: (text: string) => text || '-',
    },
    {
      title: 'Terms and Conditions',
      dataIndex: 'Terms and conditions',
      key: 'termsAndConditions',
      render: (text: string) => text || '-',
    },
    {
      title: 'Text',
      dataIndex: 'Text',
      key: 'text',
      render: (text: string) => text || '-',
    },
  ];

  // Handle API call to fetch offers
  const handleFetchOffers = () => {
    if (!imageUrl) {
      notification.error({
        message: 'Error',
        description: 'Please enter an image URL',
      });
      return;
    }
    dispatch(fetchOffers(imageUrl));
    console.log(`Fetch offers for image URL: ${imageUrl}`);
  };

  return (
    <div className={styles.competitorAnalysisContainer}>
      <div className={styles.competitorAnalysisHeader}>
        <h2 className={styles.competitorAnalysisTitle}>Analyze Competitor Website Offers</h2>
      </div>

      {/* Input for Image URL */}
      <div className={styles.inputControls}>
        <Input
          placeholder="Enter Image URL"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          className={styles.inputUrl}
        />
        <Button 
          type="primary" 
          onClick={handleFetchOffers} 
          className={styles.analyzeButton} 
          disabled={loading}
        >
          {loading ? <Spin /> : 'Analyze'}
        </Button>
      </div>

      {/* Display error message */}
      {error && (
        <div className={styles.errorText}>
          {error}
        </div>
      )}

      {/* Display loading spinner or Table */}
      {loading ? (
        <div className={styles.loadingSpinner}>
          <Spin size="large" tip="Loading offers..." />
        </div>
      ) : (
        offers.length > 0 ? (
          <Table
            columns={columns}
            dataSource={offers}
            rowKey={(_record, index = 0) => index.toString()}
            pagination={false}
            bordered
            className={styles.offerTable}
          />
        ) : (
          <div className={styles.noOffers}>
            No offers found. Please try analyzing another image URL.
          </div>
        )
      )}
    </div>
  );
};

export default CompetitorWebsiteAnalysis;
