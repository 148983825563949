

import { createAsyncThunk } from '@reduxjs/toolkit';

import { IModelDeploymentRequest } from 'src/utils/types/mLPreProcessingFlowTypes';
import { PreProcessedDataSetsService } from 'src/utils/services/service/mLPreProcessingFlowService';



export const deployModelThunk = createAsyncThunk(
      'modelDeployment/deployModel',
      async ({ data }: { data: IModelDeploymentRequest }) => {
            try {
                  const response = await PreProcessedDataSetsService.deployModel(data);
                  return response;
                } catch (error) {
                  throw new Error('Failed to create dataset: ' + error.message);
                }
                
      }
    );
