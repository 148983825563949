// src/utils/services/offerService.ts
import axios from 'axios';
import { IOfferResponse } from 'src/utils/types/offerTypes';

export const OfferService = {
  async extractOffers(imageUrl: string): Promise<IOfferResponse> {
    const response = await axios.post('https://api.dev.competitoranalysis.data.projects.excitingdevs.com/extract_offers', {
      image_url: imageUrl,
    });

    // Log the response to inspect the structure
    console.log("response: ");
    console.log(response);
    console.log("response.data: ");
    response.data = response
    console.log(response.data);
    console.log("response.data.Offers: ");
    console.log(response.data.Offers);

    return response.data;  // Ensure this data is serializable
  },
};
