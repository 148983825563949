import { createAsyncThunk } from '@reduxjs/toolkit';
import { OfferService } from 'src/utils/services/service/offerService';
import { IOffer } from 'src/utils/types/offerTypes';

export const fetchOffers = createAsyncThunk<IOffer[], string, { rejectValue: string }>(
  'offers/fetchOffers',
  async (imageUrl: string, { rejectWithValue }) => {
    try {
      const response = await OfferService.extractOffers(imageUrl);
      console.log('response from thunk: ', response);

      return response.Offers; // Extract the Offers array and return it
    } catch (error) {
      return rejectWithValue('Failed to fetch offers');
    }
  }
);


