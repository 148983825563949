import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FlowView } from '@ant-design/pro-flow';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeploymentStatus } from 'src/slices/DeployStatus/thunk'; 
import { RootState } from 'src/store';
import InferenceModal from './InferenceModal';

interface DeploymentStatusModalProps {
  isOpen: boolean;
  toggle: () => void;
  deploymentId: string;
}

const DeploymentStatusModal: React.FC<DeploymentStatusModalProps> = ({ isOpen, toggle, deploymentId }) => {
  const dispatch = useDispatch();
  const { status, progress, isLoading, error } = useSelector((state: RootState) => state.deploymentStatus);

  const [nodes, setNodes] = useState<any[]>([]);
  const [edges, setEdges] = useState<any[]>([]);
  const [isInferenceModalOpen, setIsInferenceModalOpen] = useState(false);
  const [selectedDeploymentId, setSelectedDeploymentId] = useState<string | null>(null);
  
  useEffect(() => {
    if (isOpen && deploymentId) {
      dispatch(fetchDeploymentStatus(deploymentId));
    }
  }, [isOpen, deploymentId, dispatch]);

  useEffect(() => {
  
    const baseNodes = [
      {
        id: 'node1',
        data: {
          title: 'Deployment in Progress',
          description: `Deployment ${deploymentId}`,
          style: { backgroundColor: 'yellow', borderRadius: '50%' }, 
        },
       
      },
    ];



    const baseEdges = [
      {
        id: 'edge1',
        source: 'node1',
        target: 'node2',
        type: 'smooth',
      },
    ];

  
    if (status === 'completed') {
      baseNodes.push({
        id: 'node2',
        data: {
          title: 'Deployment Completed',
          description: `Deployment ${deploymentId} complete`,
          style: { backgroundColor: 'green', borderRadius: '50%' }, 
        },
       
      });

     
      baseEdges.push({
        id: 'edge2',
        source: 'node1',
        target: 'node2',
        type: 'smooth',
      });
    }

    
    setNodes(baseNodes);
    setEdges(baseEdges);
  }, [status, deploymentId]);

 

          const handleNodeClick = (event: React.MouseEvent, node: any) => {
           
            const deploymentId = `${Math.floor(Math.random() * 10000)}`;
          
            setSelectedDeploymentId(deploymentId); 
            setIsInferenceModalOpen(true);
          };
            
  return (
      <>
         <Modal isOpen={isOpen} toggle={toggle}   modalClassName="custom-modal"
    style={{ maxWidth: '1200px' }}>
      <ModalHeader toggle={toggle}>Deployment Status</ModalHeader>
      <ModalBody>
        {isLoading && <p>Loading deployment status...</p>}
        {error && <p>Error: {error}</p>}
        {status && <p>Status: {status}</p>}
        {progress !== null && <p>Progress: {progress}%</p>}

        <div style={{ height: '400px', width: '100%' }}>
        
          <FlowView nodes={nodes} edges={edges} onNodeClick={handleNodeClick}  />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
    <InferenceModal
    isOpen={isInferenceModalOpen}
    toggle={() => setIsInferenceModalOpen(!isInferenceModalOpen)}
    deploymentId={selectedDeploymentId || ''}
  />
      
      </>
 
  );
};

export default DeploymentStatusModal;
