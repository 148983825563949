// src/services/hotelService.ts

import axios from 'axios';
import { ISearchHotelResponse } from 'src/utils/types/searchTypes';

export const HotelService = {
  async searchHotels(query: string, checkin: string, checkout: string, numberOfRooms: number, offset: number): Promise<ISearchHotelResponse> {
    try {
      const response = await axios.get<ISearchHotelResponse>('https://api.dev.competitoranalysis.data.projects.excitingdevs.com/search-hotels', {
        params: {
          query,
          checkin,
          checkout,
          number_of_rooms: numberOfRooms,
          offset,
        },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching hotel search results:', error);
      throw new Error('Failed to fetch hotel search results');
    }
  },
};
