
import { createSlice } from '@reduxjs/toolkit';
import { InitiateModelTraining } from './thunk';
import { InitiateTrainingState } from 'src/utils/types/mLPreProcessingFlowTypes';

interface InitiateTrainingStateState {
  dataSets: InitiateTrainingState[];
  isLoading: boolean;
  isCreating: boolean;
  error: string | null;
}

const initialState: InitiateTrainingStateState = {
  dataSets: [],
  isLoading: false,
  isCreating: false,
  error: null,
};

const trainingSlice = createSlice({
  name: 'dataSets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
     
      .addCase(InitiateModelTraining.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(InitiateModelTraining.fulfilled, (state, action) => {
        state.dataSets.push(action.payload as InitiateTrainingState);
        state.isCreating = false;
      })
     
      .addCase(InitiateModelTraining.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      });
      
  },
});

export default trainingSlice.reducer;
