import { Redirect } from 'react-router-dom';

//dashboard

import Dashboard from 'src/pages/Dashboard';
import TenantsList from 'src/pages/TenantsList';
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import UsersList from '../pages/UsersList';
import Anomaly from 'src/pages/Anomaly';
import CompetitorWebsiteAnalysis from 'src/pages/Competitor';
import ApiKeys from '../pages/ApiKeys';
import ImportProviders from 'src/pages/ImportProviders';
import GeographicalLocations from 'src/pages/GeographicalLocations';
import WeatherProviders from 'src/pages/WeatherProviders';
import ExternalEvents from 'src/pages/ExternalEvents';
import OccupancyPrediction from 'src/pages/Dashboard/OccupancyPrediction';
import PricingPrediction from 'src/pages/Dashboard/PricingPrediction';
import OccupancyAssistance from 'src/pages/Dashboard/OccupancyAssistance';
// import CancellationPrediction from "src/pages/Dashboard/CancellationPrediction";
import TotalRatePrediction from 'src/pages/Dashboard/TotalRatePrediction';
import CancellationPredictionBulk from 'src/pages/Dashboard/CancellationPredictionBulk';
import Groups from 'src/pages/Groups';
import Profile from 'src/pages/Profile/Index';
import Properties from 'src/pages/properties';
import NotificationContacts from 'src/pages/NotificationContacts';
import RoomPlans from 'src/pages/RoomPlans';
import SpecialPeriods from 'src/pages/SpecialPeriods';
import ExternalApiKeys from 'src/pages/ExternalApiKeys';
import MealPlans from 'src/pages/MealPlans';
import PriceList from 'src/pages/PriceList';
import ManageHotel from 'src/pages/ManageHotel';
import Tags from 'src/pages/Tags';
import Texts from 'src/pages/Texts';
import ImageManagement from 'src/pages/ImageManagement';
import CompetitorHotels from 'src/pages/CompetitorHotels';
import HotelSearch from 'src/pages/HotelSearch/HotelSearch';



import PreProcessingDataSets from 'src/pages/PreProcessingDataSets';

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //dashboard
  { path: '/dashboard', component: Dashboard },
  { path: '/tenants-list', component: TenantsList },
  { path: '/users', component: UsersList },
  { path: '/anomaly', component: Anomaly },
  { path: '/competitor-website-analysis', component: CompetitorWebsiteAnalysis },
  {path: '/hotel-search', component: HotelSearch},

  { path: '/api-keys', component: ApiKeys },
  { path: '/import-providers', component: ImportProviders },
  { path: '/geographic-locations', component: GeographicalLocations },
  { path: '/weather-providers', component: WeatherProviders },
  { path: '/external-events', component: ExternalEvents },
  { path: '/occupancy-prediction', component: OccupancyPrediction },
  { path: '/pricing-prediction', component: PricingPrediction },
  { path: '/occupancy-assistance', component: OccupancyAssistance },
  // { path : "/cancellation-prediction", component: CancellationPrediction },
  { path: '/cancellation-prediction', component: CancellationPredictionBulk },
  { path: '/total-rate-prediction', component: TotalRatePrediction },
  { path: '/groups', component: Groups },
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: '/profile', component: Profile },
  { path: '/properties', component: Properties },
  { path: '/notification-contacts', component: NotificationContacts },
  { path: '/room-plans', component: RoomPlans },
  { path: '/special-periods', component: SpecialPeriods },
  { path: '/external-api-keys', component: ExternalApiKeys },
  { path: '/meal-plans', component: MealPlans },
  { path: '/price-list', component: PriceList },
  { path: '/manage-hotel', component: ManageHotel },
  { path: '/tags', component: Tags },
  { path: '/texts', component: Texts },
  { path: '/image-management', component: ImageManagement },
  { path: '/competitor-hotels', component: CompetitorHotels },




  { path: '/data-sets', component: PreProcessingDataSets },

  

];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  { path: '/register', component: Register },
  { path: '/forgot-password', component: ForgetPwd },
];

export { userRoutes, authRoutes };
