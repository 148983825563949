import React, { useState, ChangeEvent, useEffect } from 'react';
import { Input, Button } from 'reactstrap';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface WordInputProps {
  onWordsChange: (newWords: string[]) => void;
  label?: string;
  defaultValue?: string[];
  isEmpty?: boolean;
  errorMessage?: string;
}

const WordInput: React.FC<WordInputProps> = ({ onWordsChange, label, defaultValue, isEmpty = false, errorMessage }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [words, setWords] = useState<string[]>(defaultValue || []);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { t } = useTranslation('');
  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      setWords(defaultValue);
      onWordsChange(defaultValue);
    }
  }, [defaultValue, onWordsChange]);

  useEffect(() => {
    setShowErrorMessage(isEmpty);
  }, [isEmpty]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleAddWord = () => {
    if (inputValue.trim() !== '') {
      const newWords = [...words, inputValue.trim()];
      setWords(newWords);
      setInputValue('');
      onWordsChange(newWords);
      setShowErrorMessage(false);
    }
  };

  const handleRemoveWord = (index: number) => {
    const newWords = words.filter((_, i) => i !== index);
    setWords(newWords);
    onWordsChange(newWords);
    if (words.length <= 1) {
      setShowErrorMessage(true);
    }
  };

  return (
    <div className={styles.multipleInputBlock}>
      <label>{label || 'Label'}</label>
      <div className={styles.inputBlock}>
        <Input
          style={{ border: showErrorMessage ? '1px solid red' : '' }}
          type="text"
          placeholder={t('Enter words and press Enter')}
          value={inputValue}
          onChange={handleInputChange}
        />
        <Button color="primary" onClick={handleAddWord}>
          {t('Add')}
        </Button>
      </div>
      {showErrorMessage && (
        <span style={{ color: 'red', paddingTop: '5px' }}>{errorMessage || t('Value cannot be empty')}</span>
      )}
      <div className={styles.addedBlock}>
        {words.map((word, index) => (
          <div key={index} className={styles.addedItem}>
            <span className={styles.textContent}>{word}</span>
            <span className={styles.removeButton} onClick={() => handleRemoveWord(index)}>
              x
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WordInput;
