import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Spin, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHotelSearchResults } from 'src/slices/HotelSearch/thunk';
import { RootState } from 'src/store';
import styles from './HotelSearch.module.scss'

const HotelSearch = () => {
  const [query, setQuery] = useState('');
  const [checkin, setCheckin] = useState('');
  const [checkout, setCheckout] = useState('');
  const [numberOfRooms, setNumberOfRooms] = useState(1);
  const dispatch = useDispatch();
  
  const { hotels, loading, error } = useSelector((state: RootState) => state.hotelSearch);

  // Debugging: Log state whenever it changes
  useEffect(() => {
    console.log('Hotels state updated:', hotels);
    console.log('Loading state:', loading);
    console.log('Error state:', error);
  }, [hotels, loading, error]);

  const handleSearchHotels = () => {
    if (!query || !checkin || !checkout) {
      notification.error({
        message: 'Error',
        description: 'Please fill all fields.',
      });
      return;
    }

    dispatch(fetchHotelSearchResults({ query, checkin, checkout, numberOfRooms, offset: 0 }));
  };

  const columns = [
    {
      title: 'Hotel Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => <a href={record.link} target="_blank" rel="noopener noreferrer">{text}</a>,
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
    },
    {
      title: 'Original Price',
      dataIndex: 'original_price',
      key: 'original_price',
    },
    {
      title: 'Discounted Price',
      dataIndex: 'discounted_price',
      key: 'discounted_price',
    },
    {
      title: 'Discount Percentage',
      dataIndex: 'discount_percentage',
      key: 'discount_percentage',
    },
    {
      title: 'Cancellation Policy',
      dataIndex: 'cancellation_policy',
      key: 'cancellation_policy',
    },
    {
      title: 'Prepayment Policy',
      dataIndex: 'prepayment_policy',
      key: 'prepayment_policy',
    },
  ];

  const hotelData = hotels.length > 0 ? hotels[0].hotels : [];

  return (
    <div className={styles.hotelSearchContainer}>
      <div className={styles.hotelSearchHeader}>
        <h2 className={styles.hotelSearchTitle}>Get Bookings.com Data</h2>
      </div>

      <div className={styles.searchControls}>
        <Input
          placeholder="Enter Location (e.g., Kandy)"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className={styles.searchInput}
        />
        <Input
          placeholder="Check-in Date (YYYY-MM-DD)"
          value={checkin}
          onChange={(e) => setCheckin(e.target.value)}
          className={styles.searchInput}
        />
        <Input
          placeholder="Check-out Date (YYYY-MM-DD)"
          value={checkout}
          onChange={(e) => setCheckout(e.target.value)}
          className={styles.searchInput}
        />
        <Input
          type="number"
          placeholder="Number of Rooms"
          value={numberOfRooms}
          onChange={(e) => setNumberOfRooms(parseInt(e.target.value))}
          className={styles.searchInput}
        />
        <Button type="primary" onClick={handleSearchHotels} className={styles.searchButton} disabled={loading}>
          {loading ? <Spin /> : 'Search Hotels'}
        </Button>
      </div>

      {error && <div className={styles.errorText}>{error}</div>}

      {loading ? (
        <Spin size="large" className={styles.loadingSpinner} />
      ) : (
        <Table
          columns={columns}
          dataSource={hotelData}
          rowKey={(record, index = 0) => index.toString()}
          pagination={{ pageSize: 10 }}
          className={styles.hotelTable}
        />
      )}
    </div>
  );
};

export default HotelSearch;
