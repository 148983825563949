/* eslint-disable react-hooks/exhaustive-deps */
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { FiEdit } from 'react-icons/fi';
import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { ISpecialPeriods } from 'src/utils/types/specialPeriodsTypes';
import SpecialPeriodsModal from './Components/SpecialPeriodsModal';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { DeleteSpecialPeriods, GetAllSpecialPeriods, UpdateSpecialPeriods } from 'src/slices/SpecialPeriods/thunk';
import { useTranslation } from 'react-i18next';

const SpecialPeriods = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<ISpecialPeriods | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const { isLoading, specialPeriods, isDeleting } = useSelector((state: RootState) => state.specialPeriods);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('');

  useEffect(() => {
    if (!isLoading && specialPeriods.length === 0) {
      dispatch(GetAllSpecialPeriods());
    }
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  // Function to handle toggling 'kind'
  const toggleKind = async (record: ISpecialPeriods) => {
    const updatedKind = record.kind === 'open' ? 'closed' : 'open'; // Toggle between 'open' and 'closed'
    try {
      const response = await dispatch(
        UpdateSpecialPeriods({
          eventId: record.id as string,
          data: { ...record, kind: updatedKind }, // Update the kind in the record
        }),
      );

      if ((response.payload as ISpecialPeriods)['id']) {
        openNotificationWithIcon('success', t('Special period kind updated successfully'));
        dispatch(GetAllSpecialPeriods()); // Refresh the list
      } else {
        openNotificationWithIcon('error', t('Error updating special period kind'));
      }
    } catch (error) {
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  const columns: TableProps<ISpecialPeriods>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 150,
      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateSpecialPeriod" size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="Cancel"
                okText="Proceed"
                okButtonId={`confirmAndDeleteSpecialPeriod`}
                triggerButtonId="deleteSpecialPeriod"
                onConfirm={() => {
                  deleteSpecialPeriod(record.id!);
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: t('Kind'),
      dataIndex: 'kind',
      key: 'kind',
      width: 100,
      render: (text: string) => <span>{text.charAt(0).toUpperCase() + text.slice(1)}</span>,
    },
    {
      title: t('Start Date'),
      dataIndex: 'start',
      key: 'start',
      width: 200,
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: t('End Date'),
      dataIndex: 'end',
      key: 'end',
      width: 200,
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: 300,
    },

    {
      title: t('Toggle state'),
      key: 'toggleKind',
      width: 200,
      render: (_: string, record: ISpecialPeriods) => (
        <Button
          onClick={() => toggleKind(record)}
          style={{
            backgroundColor: record.kind === 'open' ? 'red' : 'green',
            color: 'white',
            border: 'none',
            padding: '5px 10px',
          }}
        >
          {record.kind === 'open' ? t('Close') : t('Open')}
        </Button>
      ),
    },
  ];

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const deleteSpecialPeriod = async (eventId: string) => {
    if (!eventId) {
      openNotificationWithIcon('error', t('Did not find the event id'));
      return;
    }
    try {
      const response = await dispatch(
        DeleteSpecialPeriods({
          eventId,
        }),
      );
      if (response.payload === eventId) {
        openNotificationWithIcon('success', t('Special period deleted successfully'));
        dispatch(GetAllSpecialPeriods());
      } else {
        openNotificationWithIcon('error', t('Error deleting special period'));
      }
    } catch (error) {
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>{t('Special Periods')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title={t('Settings')} breadcrumbItem={t('Special Periods')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                            id="searchSpecialPeriods"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={t('Search periods by title.')}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                              id="createSpecialPeriodButton"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                                setIsEditMode(false);
                                setEditingData(null);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> {t('Add')}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableView
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={specialPeriods
                          ?.filter((event: ISpecialPeriods) => {
                            if (searchValue === '') return event;
                            else if (
                              event.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
                              event.kind?.toLowerCase().includes(searchValue.toLowerCase())
                            ) {
                              return event;
                            }
                          })
                          ?.reverse()}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <SpecialPeriodsModal
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default SpecialPeriods;
