/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { IExternalApiKeys } from 'src/utils/types/externalApiKeysTypes';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  CreateExternalApiKey,
  GetAllExternalApiKeys,
  UpdateExternalApiKey,
  ValidateApiKey,
} from 'src/slices/ExternalApiKeys/thunk';
import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { useTranslation } from 'react-i18next';

interface IExternalApiKeysModalProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IExternalApiKeys | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IExternalApiKeys | null) => void;
}

const ExternalApiKeysModal = ({
  isOpen,
  openNotificationWithIcon,
  setEditingData,
  setIsEditMode,
  toggle,
  initialValues,
  isEditMode,
}: IExternalApiKeysModalProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating, isApiKeyValidating } = useSelector((state: RootState) => state.externalApiKeys);
  const { selectedProperty, realFormate } = useSelector((state: RootState) => state.porperty);
  const dispatch: AppDispatch = useDispatch();

  const [isApiKeyChanged, setIsApiKeyChanged] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const { t } = useTranslation('');

  // Set form values when editing
  useEffect(() => {
    if (isEditMode && initialValues) {
      setIsValidated(true);
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);

  // Handle validation of API key
  const handleValidateApiKey = async () => {
    const apiKey = form.getFieldValue('apiKey');
    if (!apiKey) {
      openNotificationWithIcon('error', t('Please enter the API key'));
      return;
    }

    const response = await dispatch(ValidateApiKey({ apiKey }));
    if (response.payload) {
      setIsValidated(true);
      setIsApiKeyChanged(false);
      openNotificationWithIcon('success', t('API key validated successfully'));
    } else {
      openNotificationWithIcon('error', t('Invalid API key'));
    }
  };

  // Handle create or update logic
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IExternalApiKeys = form.getFieldsValue();

    // ** Get the current selected property id
    const propertyId = realFormate.find((property) => property.mandantId === selectedProperty?.value);
    formData.propertyId = propertyId?.id;

    if (!isValidated) {
      openNotificationWithIcon('error', t('Please validate the API key'));
      return;
    }

    if (!formData.propertyId) {
      openNotificationWithIcon('error', t('Property id is required'));
      return;
    }

    try {
      let response;
      if (isEditMode) {
        const payload = { ...formData, id: initialValues?.id };
        response = await dispatch(
          UpdateExternalApiKey({ data: payload, externalApiKeyId: initialValues?.id as string }),
        );
      } else {
        response = await dispatch(CreateExternalApiKey({ data: formData }));
      }

      if ((response.payload as IExternalApiKeys)['id']) {
        openNotificationWithIcon(
          'success',
          `${t('External API key')} ${isEditMode ? t('updated') : t('created')} ${t('Successfully')}`,
        );
        handleCancel();
        form.resetFields();
        dispatch(GetAllExternalApiKeys());
        setIsValidated(false);
      } else {
        openNotificationWithIcon(
          'error',
          `${t('Error')} ${isEditMode ? t('updating') : t('creating')} ${t('External API key')}`,
        );
      }
    } catch (error) {
      openNotificationWithIcon('error', `${t('Error')} ${isEditMode ? t('updating') : t('creating')} ${t('API key')}`);
    }
  };

  // Handle cancel/reset
  const handleCancel = () => {
    toggle();
    form.resetFields();
    setIsApiKeyChanged(false);
    setIsValidated(false);
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      setIsValidated(true);
    }
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel} tag="h5">
          {isEditMode ? t('Update') : t('Create')} {t('External API Key')}
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item required label="Name" name="name" rules={[{ required: true, message: t('Please enter name') }]}>
              <Input id="externalApiKeyName" className="p-2 px-2" placeholder="Name" />
            </Form.Item>

            <Form.Item
              required
              label={t('External System')}
              name="externalSystem"
              rules={[{ required: true, message: t('Please select external system') }]}
            >
              <Select
                id="externalSystem"
                style={{
                  height: '40px',
                }}
                placeholder={t('Please select external system')}
                options={[{ label: 'Booking', value: 'booking' }]}
              />
            </Form.Item>

            <Form.Item
              required
              label={t('API Key')}
              name="apiKey"
              rules={[{ required: true, message: t('Please enter the API key') }]}
            >
              <Input
                id="apiSecret"
                className="p-2 px-2"
                placeholder={t('API Key')}
                onChange={() => {
                  if (isEditMode) {
                    setIsApiKeyChanged(true);
                    setIsValidated(false);
                  }
                }}
              />
            </Form.Item>
          </Form>

          <Col style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              id="externalAPiKeyCancelButton"
              onClick={handleCancel}
              disabled={isCreating || isUpdating}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              {t('Cancel')}
            </Button>

            {!isValidated || isApiKeyChanged ? (
              <AsyncButton
                id="externalApiKeyValidateButton"
                buttonText="Validate"
                isLoading={isApiKeyValidating}
                onClick={handleValidateApiKey}
                style={{ marginTop: 20 }}
              />
            ) : (
              <AsyncButton
                id="externalAPiKeyCreateORUpdateButton"
                buttonText={isEditMode ? 'Update' : 'Create'}
                isLoading={isCreating || isUpdating}
                onClick={handleCreateOrUpdate}
                style={{ marginTop: 20 }}
              />
            )}
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ExternalApiKeysModal;
