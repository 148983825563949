import { Form, Input, notification, Switch, Select, Upload, Image, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RootState } from 'src/store';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useEffect, useState } from 'react';
import { IimageManagement } from 'src/utils/types/imageManagementTypes';
import { UploadOutlined } from '@ant-design/icons';
import {
  CreateImageData,
  GetAllImageData,
  UpdateImageData,
  GetSingleImageData,
} from 'src/slices/ImageManagement/thunk';
import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';

import { FiUpload, FiUploadCloud } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

interface IimageManagementProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IimageManagement | null;
  initialViewValues?: IimageManagement | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  isViewMode?: boolean;
  setIsViewMode: (isViewMode: boolean) => void;
  setEditingData: (data: IimageManagement | null) => void;
  setViewData: (data: IimageManagement | null) => void;
}

const ImageManagementModal = ({
  isOpen,
  toggle,
  openNotificationWithIcon,
  initialValues,
  initialViewValues,
  isEditMode = false,
  setIsEditMode,
  isViewMode = false,
  setIsViewMode,

  setEditingData,
  setViewData,
}: IimageManagementProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating, imageManagement } = useSelector((state: RootState) => state.imageManagement);
  const dispatch: AppDispatch = useDispatch();
  const [fileList, setFileList] = useState<any[]>([]);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { t } = useTranslation('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);

      if (initialValues?.filename) {
        setImagePreview(`${initialValues?.filename}`);
      }

      console.log('isEditMode cal');
    } else if (isViewMode && initialViewValues !== null) {
      form.setFieldsValue(initialViewValues);
      if (initialViewValues?.filename) {
        setImagePreview(`${initialViewValues?.filename}`);
      }
      console.log('isViewMode call');
    } else {
      resetFormState();
    }
  }, [initialValues, isEditMode, isViewMode, initialViewValues]);

  useEffect(() => {
    const fetchImageData = async () => {
      if (isViewMode && initialViewValues !== null) {
        setIsLoadingImage(true);
        const response = await dispatch(GetSingleImageData({ imageId: initialViewValues?.id as string }));

        console.log('API Response:', response);
        if (response?.payload) {
          const imageUrl = `data:image/png;base64,${response.payload}`;
          console.log('Fetched Image Preview URL:', imageUrl);
          setImagePreview(imageUrl);
        } else {
          console.error('No image data found in response');
        }
        form.setFieldsValue(initialViewValues);
        setIsLoadingImage(false);
      }
    };
    if (isViewMode) {
      fetchImageData();
    } else {
      resetFormState();
    }
  }, [isViewMode, initialViewValues, dispatch, form]);

  useEffect(() => {
    const fetchImageEditData = async () => {
      if (isEditMode && initialValues !== null) {
        setIsLoadingImage(true);
        const response = await dispatch(GetSingleImageData({ imageId: initialValues?.id as string }));

        console.log('API Response:', response);
        if (response?.payload) {
          const imageUrl = `data:image/png;base64,${response.payload}`;
          console.log('Fetched Image Preview URL:', imageUrl);
          setImagePreview(imageUrl);
        } else {
          console.error('No image data found in response');
        }
        form.setFieldsValue(initialValues);
        setIsLoadingImage(false);
      }
    };
    if (isEditMode) {
      fetchImageEditData();
    } else {
      resetFormState();
    }
  }, [isEditMode, initialValues, dispatch, form]);

  const onFileChange = (info: any) => {
    if (info.fileList.length > 0) {
      setFileList(info.fileList);
      const file = info.fileList[0].originFileObj;

      if (file) {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
          form.setFieldsValue({ filename: file.name });
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImagePreview(null);
      form.setFieldsValue({ filename: '' });
      setImageFile(null);
    }
  };
  const handleCreateOrUpdate = async () => {
    try {
      await form.validateFields();
      const formData: IimageManagement = form.getFieldsValue();
      console.log('Form Data: ', formData);

      const payload = new FormData();

      const data = JSON.stringify({
        hashtags: formData.hashtags,
        keywords: formData.keywords,
        enabled: formData.enabled,
      });

      payload.append('data', data);

      if (imageFile) {
        payload.append('image', imageFile);
      } else {
        throw new Error('No image file selected');
      }

      console.log('Payload to be sent:');
      for (const [key, value] of payload.entries()) {
        console.log(key, value);
      }

      if (isEditMode) {
        await dispatch(UpdateImageData({ imageId: initialValues?.id as string, data: payload }));
        openNotificationWithIcon('success', t('Image updated successfully'));
      } else {
        await dispatch(CreateImageData({ data: payload }));
        openNotificationWithIcon('success', t('Image created successfully'));
      }

      resetFormState();
      dispatch(GetAllImageData());
      toggle();
    } catch (error) {
      console.error('An error occurred: ', error);
      openNotificationWithIcon('error', `Error ${isEditMode ? t('updating') : t('creating')} image`);
    }
  };

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
    }
    if (isViewMode) {
      setIsViewMode(false);
      setViewData(null);
    }
    resetFormState();
  };

  const resetFormState = () => {
    form.resetFields();
    setFileList([]);
    setImagePreview(null);
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? t('Update') : isViewMode ? t('View') : t('Upload')} {t('Image')}
        </ModalHeader>
        <ModalBody className="scrollModal">
          {isViewMode ? (
            <div>
              <p>
                <strong>{t('File Name')}:</strong> {initialViewValues?.filename}
              </p>
              {isLoadingImage ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                  <Spin size="large" />
                </div>
              ) : (
                imagePreview && (
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                    <img width={250} src={imagePreview} alt="Preview" />
                  </div>
                )
              )}

              <p>
                <strong>{t('Hashtags')}:</strong> {initialViewValues?.hashtags?.join(', ')}
              </p>
              <p>
                <strong>{t('Keywords')}:</strong> {initialViewValues?.keywords?.join(', ')}
              </p>
              <p>
                <strong>{t('Enabled')}:</strong> {initialViewValues?.enabled ? 'Yes' : 'No'}
              </p>
            </div>
          ) : (
            <Form layout="vertical" form={form}>
              <Form.Item
                label={t('File Name')}
                name="filename"
                rules={[{ required: true, message: t('Please input the filename!') }]}
              >
                <Upload
                  accept="image/png, image/jpeg, image/gif"
                  beforeUpload={() => false}
                  fileList={fileList}
                  onChange={onFileChange}
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>{t('Choose File')}</Button>
                </Upload>
                {isLoadingImage ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                    <Spin size="large" />
                  </div>
                ) : (
                  imagePreview && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                      <Image width={250} src={imagePreview} alt="Preview" preview={false} />
                    </div>
                  )
                )}
              </Form.Item>
              <Form.Item
                id="hashtags"
                label={t('Hashtags')}
                name="hashtags"
                rules={[{ required: true, message: t('Please input the hashtags!') }]}
              >
                <Select mode="tags" style={{ width: '100%' }} placeholder={t('Enter hashtags')} />
              </Form.Item>
              <Form.Item
                id="keywords"
                label={t('Keywords')}
                name="keywords"
                rules={[{ required: true, message: t('Please input the keywords!') }]}
              >
                <Select mode="tags" style={{ width: '100%' }} placeholder={t('Enter keywords')} />
              </Form.Item>
              <Form.Item label={t('Enabled')} name="enabled" valuePropName="checked">
                <Switch id="enabled" />
              </Form.Item>
            </Form>
          )}

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="imageManagementCancelButton"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              {t('Cancel')}
            </Button>
            {!isViewMode && (
              <AsyncButton
                buttonText={isEditMode ? t('Update') : t('Create')}
                isEditMode={isEditMode}
                style={{ marginTop: 20 }}
                isLoading={isCreating || isUpdating}
                onClick={handleCreateOrUpdate}
                id="imageCreateORUpdateButton"
              />
            )}
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ImageManagementModal;
