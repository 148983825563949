import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'reactstrap';

interface AsyncButtonProps {
  id: string;
  isLoading: boolean;
  onClick: () => void;
  isEditMode?: boolean;
  createText?: string;
  updateText?: string;
  creatingText?: string;
  updatingText?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  buttonText: string;
}

const AsyncButton: React.FC<AsyncButtonProps> = ({
  id,
  isLoading,
  onClick,
  buttonText = 'Button',
  disabled = false,
  style,
}) => {
  const { t } = useTranslation('');

  return (
    <Button id={id} disabled={disabled || isLoading} onClick={onClick} style={style}>
      {isLoading ? (
        <>
          <div>
            <Spinner size="sm" />
            <span
              style={{
                marginLeft: 5,
              }}
            >
              {t(buttonText)}
            </span>
          </div>
        </>
      ) : (
        t(buttonText)
      )}
    </Button>
  );
};

export default AsyncButton;
