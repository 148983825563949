
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchModelWeights = createAsyncThunk(
  'modelWeights/getModelWeights',
  async (id: string, thunkAPI) => {
    try {
    
      const response = await new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            weights_id: 'abc123',
            file_path: '/path/to/save/model',
            algorithm: 'linear_regression',
          });
        }, 500);
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

