import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchOffers } from './thunk';
import { IOffer } from 'src/utils/types/offerTypes';

interface IOffersState {
  offers: IOffer[];
  loading: boolean;
  error: string | null;
}

const initialState: IOffersState = {
  offers: [],
  loading: false,
  error: null,
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOffers.fulfilled, (state, action: PayloadAction<IOffer[]>) => {
        console.log('Reducer received offers:', action.payload); // Debugging line
        state.offers = action.payload; // This should update the state with fetched offers
        state.loading = false;
      })
      .addCase(fetchOffers.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

export default offersSlice.reducer;
