import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification, Switch } from 'antd';
import { IimageManagement } from 'src/utils/types/imageManagementTypes';
import ImageManagementModal from './components/ImageManagementModal';
import { FiEdit, FiEye } from 'react-icons/fi';
import { Tooltip } from 'antd';

import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { GetAllImageData, DeleteImageData, GetSingleImageData } from 'src/slices/ImageManagement/thunk';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useTranslation } from 'react-i18next';

const ImageManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [editingData, setEditingData] = useState<IimageManagement | null>(null);
  const [viewData, setViewData] = useState<IimageManagement | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();

  const { isLoading, isDeleting, imageManagement } = useSelector((state: RootState) => state.imageManagement);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('');
  useEffect(() => {
    if (!isLoading && imageManagement.length === 0) {
      dispatch(GetAllImageData());
    }
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleToggleEnabled = (id: string, enabled: boolean) => {
    console.log(`Record with id ${id} is now ${enabled ? 'enabled' : 'disabled'}`);
  };
  const columns: TableProps<IimageManagement>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 100,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <Tooltip title={t('View')}>
              <div
                className="tableIcon"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsViewMode(true);
                  setViewData(record);
                }}
              >
                <FiEye id="viewImage" size={20} />
              </div>
            </Tooltip>

            <Tooltip title={t('Edit')}>
              <div
                className="tableIcon"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsEditMode(true);
                  setEditingData(record);
                }}
              >
                <FiEdit id="updateImage" size={20} />
              </div>
            </Tooltip>

            <Tooltip title={t('Delete')}>
              <div className="tableIcon">
                <DeleteButton
                  title="Are you sure you want to delete?"
                  cancelText="No"
                  okText="Yes"
                  okButtonId="confirmAndDeleteImage"
                  triggerButtonId="deleteImage"
                  onConfirm={() => {
                    deleteImage(record.id!);
                    console.log('Remove');
                  }}
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      title: t('Hashtags'),
      dataIndex: 'hashtags',
      key: 'hashtags',
      width: 200,
      render: (hashtags) => hashtags.join(', '),
    },
    {
      title: t('Keywords'),
      dataIndex: 'keywords',
      key: 'keywords',
      width: 200,
      render: (keywords) => keywords.join(', '),
    },

    {
      title: t('File name'),
      dataIndex: 'filename',
      key: 'filename',
      width: 200,
    },
    {
      title: t('Enabled'),
      dataIndex: 'enabled',
      key: 'enabled',
      width: 100,
      render: (enabled, record) => (
        <Switch
          checked={enabled}
          onChange={(checked) => {
            handleToggleEnabled(record.id as string, checked);
          }}
        />
      ),
    },
  ];

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const deleteImage = async (imageId: string) => {
    if (!imageId) {
      openNotificationWithIcon('error', t('Image id is missing'));
      return;
    }
    try {
      const response = await dispatch(
        DeleteImageData({
          imageId,
        }),
      );
      if (response.payload === imageId) {
        openNotificationWithIcon('success', t('Image deleted successfully'));
        dispatch(GetAllImageData());
      } else {
        openNotificationWithIcon('error', t('Error deleting image'));
      }
    } catch (error) {
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>{t('Image Management')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title={t('Settings')} breadcrumbItem={t('Image Management')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                            id="imageManagementSearchBar"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={t('Search image by hashtags or keywords')}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                              id="createImageManagement"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> {t('Add')}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableView
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={imageManagement
                          ?.filter((data: IimageManagement) => {
                            if (searchValue === '') return data;
                            const searchLower = searchValue.toLowerCase();

                            return (
                              data.hashtags?.some((tag) => tag.toLowerCase().includes(searchLower)) ||
                              data.keywords?.some((keyword) => keyword.toLowerCase().includes(searchLower)) ||
                              data.filename?.toLowerCase().includes(searchLower)
                            );
                          })
                          ?.reverse()}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <ImageManagementModal
              isEditMode={isEditMode}
              initialValues={editingData}
              initialViewValues={viewData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              setEditingData={setEditingData}
              setViewData={setViewData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ImageManagement;
