import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification, Tooltip } from 'antd';
import { IManageHotel } from 'src/utils/types/manageHotelTypes';
import ManageHotelModal from './components/ManageHotelModal';
import { FiEdit, FiEye } from 'react-icons/fi';

import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { GetAllManageHotelData,DeleteManageHotelData } from 'src/slices/ManageHotel/thunk';
import AbbreviatedId from 'src/components/Common/AbbreviatedId';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useTranslation } from 'react-i18next';

const ManageHotel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<IManageHotel | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isViewMode, setIsViewMode] = useState(false);
  const [viewData, setViewData] = useState<IManageHotel | null>(null);
  const [api, contextHolder] = notification.useNotification();

  const { t } = useTranslation('');

  const { isLoading, manageHotel } = useSelector((state: RootState) => state.manageHotel);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && manageHotel.length === 0) {
      dispatch(GetAllManageHotelData());
    }
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const columns: TableProps<IManageHotel>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 100,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
             <Tooltip title= {t("View")}>

             <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsViewMode(true);
                setViewData(record);
              }}
            >
              <FiEye id="viewManageHotel" size={20} />
            </div>
             </Tooltip>
        
             <Tooltip title= {t("Update")}>
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateManageHotel" size={20} />
            </div>
            </Tooltip>
            <Tooltip title= {t("Delete")}>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="No"
                okText="Yes"
                okButtonId={`confirmAndDeleteManageHotelData`}
                triggerButtonId="deleteManageHotelData"
                onConfirm={() => {
                  deleteManageHotelData(record.id!);
                }}
              />
            </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      title: t('Category'),
      dataIndex: 'category',
      key: 'category',
      width: 100,
    },
    {
      title: t('Name'),
      key: 'name',
      dataIndex: 'name',
      width: 100,
    },

    {
      title: t('Kind'),
      dataIndex: 'kind',
      key: 'kind',
      width: 100,
      filters: Array.from(new Set(manageHotel?.map((data) => data.kind))).map((kind) => ({
        text: kind,
        value: kind,
      })),
      onFilter: (value, record) => record.kind.indexOf(value as string) === 0,
    },
    {
      title: t('Segment'),
      dataIndex: 'segment',
      key: 'segment',
      width: 100,
      filters: Array.from(new Set(manageHotel?.map((data) => data.segment))).map((segment) => ({
        text: segment,
        value: segment,
      })),
      onFilter: (value, record) => record.segment.indexOf(value as string) === 0,
    },
  ];
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const deleteManageHotelData = async (hoteldataId: string) => {
    if (!hoteldataId) {
      openNotificationWithIcon('error', t('Price id not found'));
      return;
    }
    try {
    await dispatch(
        DeleteManageHotelData({
          hoteldataId,
        }),
      );
    openNotificationWithIcon('success', t('Hotel data deleted successfully'));
    dispatch(GetAllManageHotelData());
  } catch (error) {
    console.error('Error deleting Hotel Data:', error);
    if (error?.response) {
      console.error('Response Data:', error?.response?.data);
    }
    openNotificationWithIcon('error', t('Error deleting Hotel data'));
  }
  };

  console.log('view data', viewData);
  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>{t('Manage Hotel')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title={t('Settings')} breadcrumbItem={t('Manage Hotel')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                            id="manageHotelSearchBar"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={t('Search hotel by name, category, kind')}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                              id="addManageHotel"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> {t('Add')}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableView
                        loading={isLoading}
                        columns={columns}
                        dataSource={manageHotel
                          ?.filter((data: IManageHotel) => {
                            if (searchValue === '') return data;
                            else if (
                              data.category?.toLowerCase().includes(searchValue.toLowerCase()) ||
                              data.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                              data.kind?.toLowerCase().includes(searchValue.toLowerCase())
                            ) {
                              return data;
                            }
                          })
                          ?.reverse()}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <ManageHotelModal
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              initialViewValues={viewData}
              setViewData={setViewData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ManageHotel;
