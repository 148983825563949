import { useState, useEffect } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from 'reactstrap';
import env from 'src/env';

//i18n
import { withTranslation } from 'react-i18next';
// Redux
import { Link, withRouter } from 'react-router-dom';

// users
import user1 from '../../../assets/images/users/avatar-1.jpg';

//redux
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from 'antd';
import { getProfile_Picture } from 'src/slices/thunks';
import { useTranslation } from 'react-i18next';

const ProfileMenu = (props: any) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const toggle = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const { success } = useSelector((state: any) => ({
    success: state.profile.success,
  }));
  // const [user, setUser] = useState(UserList[0]);
  const getRandomColor = () => {
    const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Inside your component
  const [color, setColor] = useState(getRandomColor());

  const { userData } = useSelector((state: any) => state.login);

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState('Admin');
  const [email, setemail] = useState<string>('admin@themesbrand.com');

  useEffect(() => {
    const getAuthUser = localStorage.getItem('authUser');
    if (getAuthUser) {
      if (env.VITE_REACT_APP_DEFAULTAUTH() === 'firebase') {
        const obj = JSON.parse(getAuthUser);
        if (obj.displayName) {
          setusername(obj.displayName);
        } else {
          setusername(obj.fullName);
        }
        setemail(obj.email);
      } else if (env.VITE_REACT_APP_DEFAULTAUTH() === 'fake' || env.VITE_REACT_APP_DEFAULTAUTH() === 'jwt') {
        const obj = JSON.parse(getAuthUser);
        if (obj.username) {
          setusername(obj.username);
          setemail(obj.email);
        } else {
          setusername(obj.name);
        }
      }
    }
  }, [success]);

  const { isProfilePictureLoading, profilePicture } = useSelector((state: any) => state.profile);
  const [imageSrc, setImageSrc] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfile_Picture());
  }, []);

  useEffect(() => {
    const imageBlob = new Blob([profilePicture], { type: 'image/jpeg' }); // Adjust the type if your image is not JPEG
    const imageUrl = URL.createObjectURL(imageBlob);
    setImageSrc(imageUrl);
  }, [profilePicture]);

  const { t } = useTranslation('');

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" /> */}

          <Avatar style={{ verticalAlign: 'middle' }} src={isProfilePictureLoading ? null : imageSrc} size="large">
            {`${userData?.given_name?.slice(0, 1) ?? ''} ${userData?.family_name?.slice(0, 1) ?? ''}`}
          </Avatar>
          <span className="ms-2 d-none d-sm-block user-item-desc">
            <span className="user-name">{userData?.name}</span>
            <span className="user-sub-title">{userData?.preferred_username}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
          <div className="p-3 bg-primary border-bottom">
            <h6 className="mb-0 text-white">{userData?.preferred_username}</h6>
            <p className="mb-0 font-size-11 text-white-50 fw-semibold">{userData?.email}</p>
          </div>
          <Link to={'/profile'}>
            <DropdownItem tag="a" href="/profile">
              <i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>{' '}
              <span className="align-middle">{props.t('Profile')}</span>
            </DropdownItem>
          </Link>
          {/* <DropdownItem tag="a" href=""><i className="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{props.t("Messages")}</span></DropdownItem>
          <DropdownItem tag="a" href=""><i className="mdi mdi-calendar-check-outline text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{props.t("Taskboard")}</span></DropdownItem>
          <DropdownItem tag="a" href=""><i className="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{props.t("Help")}</span></DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag="a" href="#"><i className="mdi mdi-wallet text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{props.t("Balance")} : <b>$6951.02</b></span></DropdownItem>
          <DropdownItem tag="a" className="d-flex align-items-center" href=""><i className="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{props.t("Settings")}</span><span className="badge badge-soft-success ms-auto">New</span></DropdownItem> */}
          {/* <DropdownItem tag="a" href="/">
            <i className="mdi mdi-lock text-muted font-size-16 align-middle me-1"></i>{' '}
            <span className="align-middle">{props.t('Lock screen')} </span>
          </DropdownItem> */}

          <DropdownItem onClick={() => setIsConfirmModalOpen(true)}>
            <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>{' '}
            <span className="align-middle">{props.t('Logout')}</span>
          </DropdownItem>

          <Modal isOpen={isConfirmModalOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {t('Are you sure you want to log out')}
            </ModalHeader>

            <ModalBody className="scrollModal">
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'end', gap: '10px' }}>
                <Button onClick={toggle}>{t('Cancel')}</Button>
                <a href="/logout">
                  <Button>{t('Yes')}</Button>
                </a>
              </div>
            </ModalBody>
          </Modal>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

// tag="a" href="/logout"

export default withTranslation()(withRouter(ProfileMenu));
