import api from '../axiosConfig';
import env from '../../../env';
import axios from 'axios';

const baseURL = env.VITE_ROOM_INFORMATION_DEFAULT_BASE_URL();
const roomConfigurationBaseUrl = '/competitors/rooms';

const getAllRoomConfigurations = async (): Promise<IRoomInformation[] | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IRoomInformation[] = await axios.get(`${roomConfigurationBaseUrl}`, { headers, baseURL });
    return response;
  } catch (error) {
    console.error('Error fetching all room information:', error);
    throw error;
  }
};

export const RoomInformationService = {
  getAllRoomConfigurations,
};
