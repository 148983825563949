/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { IRoomConfiguration } from 'src/utils/types/roomConfigurationsTypes';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Input, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { CreateRoomPlan, GetAllRoomPlans, UpdateRoomPlan } from 'src/slices/RoomPlan/thunk';
import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { roomTypes } from 'src/constants/room-types';
import { useTranslation } from 'react-i18next';
type NotificationType = 'success' | 'info' | 'warning' | 'error';
interface IRoomPlanModalProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IRoomConfiguration | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IRoomConfiguration | null) => void;
}

const RoomPlanModal = ({
  isOpen,
  openNotificationWithIcon,
  setEditingData,
  setIsEditMode,
  toggle,
  initialValues,
  isEditMode,
}: IRoomPlanModalProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating, roomConfigurations } = useSelector((state: RootState) => state.roomConfiguration);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('');

  // ** useEffect to set initial values in form
  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      console.log('initialValues', initialValues);
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);

  const handleCancel = () => {
    toggle();
    form.resetFields();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      //   form.resetFields();
    }
  };

  // ** Function to handle create or update
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IRoomConfiguration = form.getFieldsValue();
    console.log(formData);

    try {
      if (isEditMode) {
        const payload = formData;
        payload.id = initialValues?.id;

        const response = await dispatch(
          UpdateRoomPlan({
            data: payload,
          }),
        );

        // ** Handled Response
        if ((response.payload as IRoomConfiguration)['id']) {
          openNotificationWithIcon('success', t('Room plan updated  successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllRoomPlans());
        } else {
          openNotificationWithIcon('error', t(`Error updating room plan`));
        }
      } else {
        // ** Create Operation
        const payload = formData;

        const isRoomNameExist = roomConfigurations.some((room) => room.name === payload.name);
        if (isRoomNameExist) {
          openNotificationWithIcon('error', t('Name should be unique'));
          return;
        }

        const response = await dispatch(
          CreateRoomPlan({
            data: payload,
          }),
        );

        console.log(response);

        // ** Handled Response
        if ((response.payload as IRoomConfiguration)['id']) {
          openNotificationWithIcon('success', t('Room plan created  successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllRoomPlans());
        } else {
          openNotificationWithIcon('error', t(`Error creating room plan`));
        }
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon('error', `${'Error'} ${isEditMode ? t('updating') : t('creating')} ${t('Room Plan')}`);
    }
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? t('Update') : t('Create')} {t('Room Plan')}
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: t('Please enter room name'),
                },
              ]}
            >
              <Input id="roomPlanName" placeholder="Name" className="p-2 px-2" />
            </Form.Item>
            <Form.Item
              required={true}
              label={t('Title')}
              name="title"
              rules={[
                {
                  required: true,
                  message: t('Please enter  room title'),
                },
              ]}
            >
              <Input id="roomTitle" placeholder={t('Room title')} className="p-2 px-2" />
            </Form.Item>
            <Row className="gap-1">
              <Col>
                <Form.Item
                  required={true}
                  label={t('Count')}
                  name="count"
                  rules={[
                    {
                      required: true,
                      message: t('Please enter  room count'),
                    },
                  ]}
                >
                  <Input type="number" id="roomCount" placeholder={t('Room count')} className="p-2 px-2" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  required={true}
                  label={t('Room Type')}
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: t('Please select room type'),
                    },
                  ]}
                >
                  <Select
                    id="roomType"
                    allowClear
                    placeholder={t('Select room type')}
                    style={{
                      height: '40px',
                    }}
                    showSearch
                  >
                    {roomTypes.map((roomType) => (
                      <Select.Option value={roomType} key={roomType}>
                        {t(roomType)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              required={true}
              label={t('Description')}
              name="description"
              rules={[
                {
                  required: true,
                  message: t('Please enter room description'),
                },
              ]}
            >
              <Input.TextArea id="roomDescription" placeholder={t('Room description')} className="p-2 px-2" />
            </Form.Item>
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Button
              id="roomPlanCancelButton"
              disabled={isCreating || isUpdating}
              onClick={handleCancel}
              style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
            >
              {t('Cancel')}
            </Button>
            <AsyncButton
              buttonText={isEditMode ? 'Update' : 'Create'}
              isEditMode={isEditMode}
              style={{ marginTop: 20 }}
              isLoading={isCreating || isUpdating}
              onClick={handleCreateOrUpdate}
              id="roomPlanCreateORUpdateButton"
            />
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RoomPlanModal;
