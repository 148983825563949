


import { createAsyncThunk } from '@reduxjs/toolkit';

import { PreProcessedDataSetsService } from 'src/utils/services/service/mLPreProcessingFlowService';

export const submitInferenceThunk = createAsyncThunk(
  'inference/submitInference',
  async (inferenceData: any) => {
    try {
      const response = await PreProcessedDataSetsService.submitInference(inferenceData);
      return response;
    } catch (error) {
      return error;
    }
  }
);




    