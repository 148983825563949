import { createSlice } from '@reduxjs/toolkit';
import { IText } from 'src/utils/types/textTypes';
import { GetAllTexts, CreateNewTextEntry, DeleteTextEntry, GetSingleText, UpdateTextEntry } from './thunk';

interface ITextsState {
  texts: IText[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: ITextsState = {
  texts: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const textsSlice = createSlice({
  name: 'texts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllTexts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllTexts.fulfilled, (state, action) => {
        state.texts = action.payload as IText[];
        state.isLoading = false;
      })
      .addCase(GetAllTexts.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateNewTextEntry.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateNewTextEntry.fulfilled, (state) => {
        state.isCreating = false;
      })
      .addCase(CreateNewTextEntry.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateTextEntry.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateTextEntry.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(UpdateTextEntry.rejected, (state, action) => {
        state.isUpdating = false;
        console.log(action);
      })
      .addCase(DeleteTextEntry.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteTextEntry.fulfilled, (state) => {
        state.isDeleting = false;
      })
      .addCase(DeleteTextEntry.rejected, (state) => {
        state.isDeleting = false;
      });
  },
});

export default textsSlice.reducer;
